












































import Vue, { PropType } from "vue";
import router from "@/routes/router";

import { copyToClipboard } from "@/util/helpers";

import {
  getMainBookingPageUrl,
} from "@/data/appointmentService";
export default Vue.extend({
  name: "app-type-group-main-page-section",
  data() {
    return {};
  },
  props:{
    bookingPageName:{
        type:String,
        default:""
    },
    ownerUrl:{
        type:String,
        default:""
    }
  },
   methods:{
    copyMainBookingUrlToClipboard(){
        let destinationUrl = getMainBookingPageUrl(router, this.ownerUrl, window);
      copyToClipboard(destinationUrl);
      this.$showAlertBar(
        "Link copiato correttamente, ora invialo a chi deve prenotare ( su Whatsapp, Mail, ecc )",
        "success"
      );
    }
  },
   computed:{
    mainBookingPageUrl(): string {
      return getMainBookingPageUrl(router, this.ownerUrl, window);
      //return "";
    },
  }
});
