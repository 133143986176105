



























































































































































import {
  changeSubscription,
  finalizeSubscriptionPayment,
  getCustomerCards,
  initSubscriptionChange
} from "@/data/subscriptionService";
import { setBillingDetail } from "@/data/account";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import {GetCustomerCardsResponse} from "@/data/Models/GetCustomerCardsResponse";
import "@/data/Models/InitSubscriptionChangeResponse";
import BillingDetails from "@/components/SubscriptionPayment/BillingDetails.vue";

import { loadStripe,Stripe } from "@stripe/stripe-js";
import Vue, { VueConstructor, PropType } from "vue";
import globalMixins from "@/util/mixins/MyGlobalMixins.vue";


export default (Vue as VueConstructor<
  Vue & InstanceType<typeof globalMixins>
>).extend({
  components: {
    StripeElementCard,
    BillingDetails
  },

  data() {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      customerCards: [] as GetCustomerCardsResponse[],
      

      paymentElement: undefined,
      isStripeReady: false,
      clientSecret: "",
      
      selectedCard: "newCard",
      /**
       * @type InitSubscriptionChangeResponse
       */ 
      planChangeInfo: undefined as undefined|any,
      paymentInProgress: false,
      stripe: {} as any,
      elements: () => {},
    };
  },
async mounted() {
    try {
      this.stripe = await loadStripe(this.publishableKey);
      this.customerCards = await getCustomerCards();
      //Se ho carte, seleziono la prima.
      if (this.customerCards.length > 0) {
        this.selectedCard = this.customerCards[0].id;
      }

      await this.getChangePlanSummary();
    } catch (ex) {
      //se catcha qualcosa rimando a subscription sempre.

      this.$router.push({
        name: "subscription"
      });
      throw ex;
    }
  },
methods: {
    async getChangePlanSummary() {
      this.planChangeInfo = await initSubscriptionChange(this.planId);
      
    },

    async initPayment() {
      try {
let billingDetailsComponent = this.$refs.billingDetailsComponent as any;

        if (
          (await billingDetailsComponent.manualValidation()) === true || this.planChangeInfo.totalPriceToPay===0
        ) {
          debugger;
          this.paymentInProgress = true;
          //save billing details
          this.planChangeInfo.billingDetail = billingDetailsComponent.billingDetail;
          await setBillingDetail(this.planChangeInfo.billingDetail);

          if (this.selectedCard === "newCard") {
            //In questo caso recupero il token della nuova carta
            this.getCardToken();
          } else {
            //in questo caso passo direttamente il paymentId

            let response = await changeSubscription({
              newSubscriptionId: this.planChangeInfo.newSubscriptionId,
              paymentId: this.planChangeInfo.paymentId,
              paymentMethodId: this.selectedCard,
            });

            await this.handleChangeFinalizeSubscriptionResponse(response);
          }
          this.paymentInProgress = false;
        }
      } catch (ex) {
        this.paymentInProgress = false;
        throw ex;

      }
    },
    getCardToken() {
      // this will trigger the process
      let elementRef = this.$refs.elementRef as any;
      elementRef.submit();
      //this.$refs.elementRef.submit();
    },
    async tokenCreated(token) {
      let response = await changeSubscription({
        newSubscriptionId: this.planChangeInfo.newSubscriptionId,
        paymentId: this.planChangeInfo.paymentId,
        cardToken: token.id,
                
      });

      await this.handleChangeFinalizeSubscriptionResponse(response);
    },
    async handleChangeFinalizeSubscriptionResponse(response) {
      if (response.requires_action) {
        // Use Stripe.js to handle required card action
        let cardActionResult = await this.stripe.handleCardAction(
          response.payment_intent_client_secret
        );

        let result = await this.handleStripeJsResult(cardActionResult);
      } else {
        //Se tutto ok
        this.$showAlertBar("Abbonamento attivato correttamente.", "success");
        this.$router.push({
          name: "subscription"
        });
      }
    },
    async handleStripeJsResult(result) {
      if (result.error) {
        // Show error in payment form
        //throw result.error;
        this.$showAlert(
          "Il pagamento non è andato a buon fine. Si prega di riprovare."
        );
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server

        let response = await finalizeSubscriptionPayment(
          result.paymentIntent.id
        );

        await this.handleChangeFinalizeSubscriptionResponse(response);
      }
    }
  },
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  
});
