import apiClient from "../util/ApiClient";

/**
 *
 * @param {File} file
 * @returns
 */
export const uploadLogo = async function(file) {
  const formData = new FormData();
  formData.append("File", file);

  const response = await apiClient.post(`Files/UploadLogo`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  return response.data;
};

export const deleteLogo = async function () {

    return apiClient.delete('Files/DeleteLogo');
}
