import { render, staticRenderFns } from "./AppTypeGroup.vue?vue&type=template&id=4a4fd7da&scoped=true&"
import script from "./AppTypeGroup.vue?vue&type=script&lang=ts&"
export * from "./AppTypeGroup.vue?vue&type=script&lang=ts&"
import style0 from "./AppTypeGroup.vue?vue&type=style&index=0&id=4a4fd7da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4fd7da",
  null
  
)

export default component.exports