var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"overrideValidator"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',{staticClass:"form-control-label"},[_vm._v(" Dal giorno ")]),_c('validation-provider',{attrs:{"rules":{ required: true },"name":"Dal giorno","vid":_vm.generateUniqueId()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({attrs:{"locale":"it-IT","min":_vm.minSelectableDate,"max":_vm.maxSelectableDate,"start-weekday":"1"},model:{value:(_vm.uiOverride.sDate),callback:function ($$v) {_vm.$set(_vm.uiOverride, "sDate", $$v)},expression:"uiOverride.sDate"}},'b-form-datepicker',_vm.labels,false)),_vm._t("error",[(errors[0])?_c('div',{staticClass:"invalid-feedback timeInputError",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',{staticClass:"form-control-label"},[_vm._v(" Al giorno ")]),_c('validation-provider',{attrs:{"rules":{
                  required: true,
                  stringDateMustBeEqualOrGreatherThan: [
                    'Dal giorno',
                    _vm.uiOverride.sDate
                  ]
                },"name":"Al giorno","vid":_vm.generateUniqueId()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({attrs:{"locale":"it-IT","min":_vm.minSelectableDate,"max":_vm.maxSelectableDate,"start-weekday":"1"},model:{value:(_vm.uiOverride.eDate),callback:function ($$v) {_vm.$set(_vm.uiOverride, "eDate", $$v)},expression:"uiOverride.eDate"}},'b-form-datepicker',_vm.labels,false)),_vm._t("error",[(errors[0])?_c('div',{staticClass:"invalid-feedback timeInputError",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',{staticClass:"form-control-label"},[_vm._v(" Sarò disponibile in questi orari: ")])])],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('time-table-row',{attrs:{"day-name":"","availabilities":_vm.uiOverride.availabilities},on:{"day-time-table-add-interval":function($event){return _vm.onIntervalAddRequest()},"day-time-table-remove-interval":function($event){return _vm.onIntervalRemoveRequest($event)}}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"text-right"},[_c('base-button',{staticClass:"mt-3",attrs:{"type":"link"},on:{"click":_vm.emitCanceled}},[_vm._v("Annulla")]),_c('base-button',{staticClass:"mt-3",attrs:{"type":"primary"},on:{"click":_vm.saveOverrides}},[_vm._v("Salva")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }