export interface ReceiverTeamInvitation {
    id: string;
    teamName: string;
    expirationDate: Date;
  }

  export function createDefaultReceiverTeamInvitation(): ReceiverTeamInvitation {
    let defaultValue: ReceiverTeamInvitation = {
      id: "",
      teamName:"",
      expirationDate:new Date(2099,11,31),
    };
  
    return defaultValue;
  }
 