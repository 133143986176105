<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Inserisci la tua nuova password</h1>
              <!-- <p class="text-lead text-white">
                Da questa pagina puoi resettare la tua password.<br/>
                Inserisci qua sotto la tua mail per procedere.
              </p> -->
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <!-- <b-card-header class="bg-transparent pb-5"  >
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <!-- <small>Effettua l'accesso</small> -->
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    vid="password"
                    alternative
                    class="mb-3"
                    name="Password"
                    label="Password"
                    :rules="{ required: true, min: 8 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                    v-model="model.password"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    name="Conferma Password"
                    label="Reinserisci la password"
                    :rules="{ confirmed: 'password' }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                    v-model="model.confirmedPassword"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      >Imposta la nuova Password</base-button
                    >
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import * as account from "@/data/account";
export default {
  data() {
    return {
      model: {
        resetToken: "",
        id: -1,
        password: "",
        confirmedPassword: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      await account.resetPassword(this.model);

      this.$router.push({ name: "login" });
      this.$showAlertSuccess("Password cambiata correttamente", "success");
    },
    loadDataFromQueryString() {
      this.model.resetToken = this.$route.query.token;
      this.model.id = this.$route.query.id;
    },
  },
  mounted() {
    this.loadDataFromQueryString();
  },
};
</script>
