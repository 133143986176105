











































// import "@/data/Models/Override/DayOverrideDTO";
// import "@/data/Models/Override/OverrideIntervalDTO";
import RangeOverrideDTO from "@/data/Models/Override/RangeOverrideDTO";

// import { getAppointmentTypeOverrides } from "@/data/availabilitySet.js";
import { schedeoFormatDate, toIsoDateString } from "@/util/dateManager";
import { add } from "date-fns";
import { diff } from "deep-object-diff";
import Vue, { PropType } from "vue";
import { DayOverrideDTO } from "@/data/Models/Override/DayOverrideDTO";

export default Vue.extend({
  name: "availability-set-override-table",
  data() {
    return {
      //overrides: [],
      schedeoFormatDate,
      diff
    };
  },
  props: {
    availabilitySetId: {
      type: Number,
      default: 0
    },

    overrides: {
      type: Array as PropType<Array<DayOverrideDTO>>,
      default: () => []
    }
  },
  // async mounted() {
  //   await this.loadOverrides();
  // },

  methods: {
    // async loadOverrides() {
    //   this.overrides = await getAppointmentTypeOverrides(
    //     this.availabilitySetId,
    //     new Date(),
    //     new Date(2099, 1, 1)
    //   );
    // }
    emitDeleteOverride(sDate, eDate) {
      this.$emit("deleteOverride", sDate, eDate);
    },
    async onDeleteClick(sDate, eDate) {
      let periodoString = "";
      if (sDate == eDate)
        periodoString = `del giorno ${schedeoFormatDate(sDate, "PPPP")}`;
      else {
        periodoString = `da ${schedeoFormatDate(sDate, "PPPP")} a 
        ${schedeoFormatDate(eDate, "PPPP")}`;
      }
      let confirmed = await this.$showConfirmDelete(
        `Sicuro di voler cancellare gli orari sostitutivi ${periodoString}?`
      );
      if (confirmed) this.emitDeleteOverride(sDate, eDate);
    }
  },
  computed: {
    rangeOverrides(): Array<RangeOverrideDTO> {
      let result: Array<RangeOverrideDTO> = [];

      let sortedOverridesCopy = this.overrides
        .slice(0)
        .sort((first, second) => {
          let result = first.day.localeCompare(second.day);
          return result;
        });

      sortedOverridesCopy.forEach(item => {
        //Vedo se ho già nell'aggregato la data precedente
        let previousDayString = toIsoDateString(
          add(new Date(item.day), { days: -1 })
        );
        let rangeOverride = result.find(o => o.eDate == previousDayString);

        if (rangeOverride !== undefined) {
          //ottengo le differenze tra i due oggetti
          let differences = this.diff(item.intervals, rangeOverride.intervals);

          //Ottengo se ci sono differenze
          let hasSameIntervals = Object.keys(differences).length == 0;

          if (hasSameIntervals) {
            //Inserisco nel range
            rangeOverride.eDate = item.day;
            //returno perchè ho finito e termino questa iterazione per passare alla prox ( come un next; )
            return;
          }
        }
        //Se arrivo qui significa che devo aggiungere il range
        result.push({
          availabilitySetId: item.availabilitySetId,
          sDate: item.day,
          eDate: item.day,
          intervals: item.intervals
        });
      });

      return result;
    }
  }
});
