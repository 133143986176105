<template>
  <div class="text-left">
    <b-card no-body header-class="p0" class="page-card">
      <b-card-header class="p0">
        <div class="d-flex align-items-stretch">
          <div
            class="clickable mr-4 pb-2"
            @click="onUpcomingEventsClick"
            :class="selectedTab === 1 ? 'selected-tab' : ''"
          >
            Prossimi Appuntamenti
          </div>
          <div
            class="clickable pr-1"
            @click="onPastEventsClick"
            :class="selectedTab === 2 ? 'selected-tab' : ''"
          >
            Appuntamenti Passati
          </div>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="filter-section">
          <label class="form-control-label">Ricerca Appuntamenti</label>
          <label class="form-control-label helpLabel" @click="showHelpSearch">
            (Istruzioni)
          </label>
          <b-input-group class="">
            <b-form-input
              style="max-width:300px;z-index:auto;"
              v-model="eventsFilter.searchText"
              @keyup.enter="loadEvents"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                v-if="this.eventsFilter.searchText !== ''"
                variant="outline-primary"
                @click="clearSearchText"
                >X</b-button
              >
              <b-button variant="primary" @click="loadEvents">Cerca</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div v-if="this.eventsByDay.length > 0">
          <div
            v-for="(dayEventGroup, index) in eventsByDay"
            :key="index"
            class="day-container"
          >
            <div class="day-title">
              {{ dayEventGroup.dayString }}
            </div>
            <div
              v-for="event in dayEventGroup.events"
              :key="event.id"
              class="app"
            >
              <b-row>
                <b-col cols="12" lg="3">
                  <div class="app-hours">
                    <span
                      v-if="
                        !isSameDay(
                          parseISO(event.startDate),
                          parseISO(event.endDate)
                        )
                      "
                      >{{ schedeoFormatDate(event.startDate, "P") }}
                    </span>
                    {{ schedeoFormatDate(event.startDate, "p") }} -
                    <span
                      v-if="
                        !isSameDay(
                          parseISO(event.startDate),
                          parseISO(event.endDate)
                        )
                      "
                      >{{ schedeoFormatDate(event.endDate, "P") }}
                    </span>
                    {{ schedeoFormatDate(event.endDate, "p") }}
                  </div>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="app-summary">
                    <div class="app-title">{{ event.title }}</div>
                    <div class="pt-2">{{ event.appointmentTypeName }}</div>
                  </div>
                </b-col>
                <b-col cols="12" lg="3">
                  <div class="app-actions">
                    <div
                      class="link-no-underline fs-14"
                      v-b-toggle="'collapse-event-' + event.id"
                    >
                      <span class="when-collapsed"
                        >Visualizza Dettagli Appuntamento</span
                      >
                      <span class="when-not-collapsed"
                        >Nascondi Dettagli Appuntamento</span
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-collapse :id="'collapse-event-' + event.id">
                <b-row class="pt-4">
                  <b-col cols="12" lg="3"></b-col>
                  <b-col cols="12" lg="6">
                    <div class="app-detail">
                      <div>
                        <strong>Nome:</strong>
                        {{ event.eventAttendees[0].fullName }}
                      </div>
                    </div>
                    <div class="app-detail">
                      <div>
                        <strong>Mail:</strong>
                        {{ event.eventAttendees[0].mail }}
                      </div>
                    </div>

                    <div class="app-detail">
                      <div>
                        <strong>Telefono:</strong>
                        {{ event.eventAttendees[0].phone }}
                      </div>
                    </div>
                    <div class="app-detail" v-if="event.location">
                      <div>
                        <strong>Dove:</strong>
                        {{ event.location }}
                      </div>
                    </div>
                    <div
                      class="app-detail"
                      v-if="event.eventAttendees[0].notes"
                    >
                      <div><strong>Note del cliente:</strong></div>
                      <pre>{{ event.eventAttendees[0].notes }}</pre>
                    </div>
                    <div class="app-detail">
                      <div><strong>Organizzatori:</strong></div>
                      <ul>
                        <li v-for="o in event.eventOrganizers" :key="o.id">
                          {{ o.userUsername }}
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="3">
                    <div class="pb-3">
                      <router-link
                        :to="{
                          name: 'reschedule',
                          params: { eventId: event.id }
                        }"
                        target="_blank"
                      >
                        <b-button variant="primary">
                          Rischedula
                        </b-button></router-link
                      >
                    </div>
                    <div>
                      <router-link
                        :to="{
                          name: 'cancel',
                          params: { eventId: event.id }
                        }"
                        target="_blank"
                        ><b-button variant="primary"
                          >Annulla</b-button
                        ></router-link
                      >
                    </div>
                    <!-- <div class="link-no-underline pb-3">
                      
                    </div>
                    <div class="link-no-underline">
                      
                    </div> -->
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
          </div>
          <b-pagination
            v-if="this.eventsByDay.length > 0"
            v-model="eventsPagination.currentPage"
            :total-rows="eventsPagination.totalRows"
            :per-page="eventsPagination.perPage"
            @input="this.loadEvents"
            class="pt-3"
          ></b-pagination>
        </div>
        <div
          v-if="
            this.eventsByDay.length === 0 &&
              this.$store.getters.isLoading === false
          "
          class="pt-5"
        >
          <div class="text-center no-appointment">
            Nessun Appuntamento da visualizzare...
          </div>
          <div style="max-width:500px;" class="ml-auto mr-auto">
            <b-img src="/img/images/undraw_empty_street_sfxm.png" fluid />
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
/**
 * @typedef {Object} DayEventGroup
 * @property {date} day
 * @property {string} dayString
 * @property {Array<Event_Get_DTO>} events
 */

import { getEvents } from "@/data/event.js";
import { cloneDeep } from "lodash";
import { schedeoFormatDate } from "@/util/dateManager";
import { startOfDay, parseISO, isSameDay } from "date-fns";
export default {
  data() {
    return {
      schedeoFormatDate,
      parseISO,
      isSameDay,
      //events: [],
      /**
       * @type {Array<DayEventGroup>}
       */
      eventsByDay: [],
      eventsPagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 10
      },
      eventsFilter: {
        startDate: new Date(),
        endDate: new Date(2099, 11, 31),
        sort: "asc",
        searchText: ""
      },
      selectedTab: 1
    };
  },
  props: {
    intro: {
      //Se questo è a true significa che devo gestire l'intro per i nuovi iscritti ( messaggi particolari, ecc)
      type: Boolean,
      default: false
    }
  },
  methods: {
    clearSearchText() {
      this.eventsFilter.searchText = "";
      this.loadEvents();
    },

    onUpcomingEventsClick(event) {
      this.selectedTab = 1;

      this.eventsFilter.startDate = new Date();
      this.eventsFilter.endDate = new Date(2099, 11, 31);
      this.eventsFilter.sort = "asc";
      this.eventsPagination.currentPage = 1;

      this.loadEvents();
    },
    onPastEventsClick(event) {
      this.selectedTab = 2;

      this.eventsFilter.startDate = new Date(2020, 1, 1);
      this.eventsFilter.endDate = new Date();
      this.eventsFilter.sort = "desc";
      this.eventsPagination.currentPage = 1;
      this.loadEvents();
    },
    groupByDay(events) {
      let groupedEvents = events.reduce((accumulator, event) => {
        let groupName = schedeoFormatDate(new Date(event.startDate), "PPPP");

        let clonedAccumulator = cloneDeep(accumulator);
        let group = clonedAccumulator.filter(e => e.dayString === groupName)[0];

        if (group !== undefined) {
          //Se esiste già il gruppo aggiungo l'item
          group.events.push(event);
          //newAccumulator[group] = newAccumulator[group].concat(event);
        } else {
          //aggiungo gli eventi al gruppo
          group = {};
          group.dayString = groupName;
          group.day = startOfDay(parseISO(event.startDate));
          group.events = [event];

          //aggiungo il gruppo all'accumulator
          clonedAccumulator.push(group);
          //newAccumulator[group] = newAccumulator[group] = [event];
        }
        return clonedAccumulator;
      }, []);
      return groupedEvents;
    },
    async loadEvents() {
      let pagedListEvents = await getEvents({
        startDate: this.eventsFilter.startDate,
        endDate: this.eventsFilter.endDate,
        pageNumber: this.eventsPagination.currentPage,
        pageSize: this.eventsPagination.perPage,
        sort: this.eventsFilter.sort,
        searchText: this.eventsFilter.searchText
      });
      //Raggruppo per giorno
      this.eventsByDay = this.groupByDay(pagedListEvents.items);

      this.eventsPagination.currentPage = pagedListEvents.currentPage;
      this.eventsPagination.totalRows = pagedListEvents.totalCount;
      this.eventsPagination.perPage = pagedListEvents.pageSize;

      //this.sortEventsByDay();
    },
    async showHelpSearch() {
      await this.$showHtmlModalMessage(
        "Info",
        `
        Qui puoi ricercare per nome, mail o telefono di chi ha preso appuntamento con te.
        `
      );
    }
  },
  async mounted() {
    await this.loadEvents();

    if (this.intro === true) {
      let msg =
        "Un'ultima cosa importante:\r\n\r\nIl tuo link di appuntamento da inviare ai clienti/colleghi lo trovi nella sezione Tipi di Appuntamento";
      await this.$showTimedModalMessage("Introduzione terminata", msg, 5);
    }
  }
};
</script>
<style scoped>
.filter-section {
  margin-bottom: 2rem;
  /* height: 3rem; */
}

.no-appointment {
  font-size: 1.2rem;
  font-weight: bold;
}

.selected-tab {
  border-bottom: 3px solid #2dcec5;
}
.card-header {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 1.5rem;
}

.day-container:first-child {
  margin-top: 0rem;
}
.day-container {
  margin-top: 2rem;
}

.day-title {
  font-size: 1.5rem;
  padding: 1rem 0 0 0;
  border-bottom: 1px solid silver;
  /* background-color: silver;
  border-radius: 10px 10px 0px 0px; */
}
.app {
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 1px dashed silver;
}
.app:last-child {
  border-bottom: none;
}
.app-title {
  font-weight: bold;
}

.app-detail {
  padding-bottom: 0.5rem;
}
.collapsed > .when-not-collapsed,
.not-collapsed > .when-collapsed {
  display: none;
}
</style>
