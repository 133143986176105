export const getBFormDatePickerItalianLabels = function() {
   return {
    labelPrevDecade: 'Decennio precedente',
    labelPrevYear: 'Anno precedente',
    labelPrevMonth: 'Mese precedente',
    labelCurrentMonth: 'Mese corrente',
    labelNextMonth: 'Mese prossimo',
    labelNextYear: 'Il prossimo anno',
    labelNextDecade: 'Prossimo decennio',
    labelToday: 'Oggi',
    labelSelected: 'Data selezionata',
    labelNoDateSelected: 'Nessuna data selezionata',
    labelCalendar: 'Calendario',
    labelNav: 'Navigazione calendario',
    labelHelp: 'Utilizza i tasti freccia per navigare nel calendario'
  }
  };