<template>
  <div>
    <!-- class="main-content bg-default bg-gradient-success" -->
    <section>
      <b-container>
        <b-row>
          <b-col>
            <img
              src="img/brand/Logo-schedeo-V2-Transp.png"
              class="pb-3 pt-3 logo"
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="bg-gradient-success pt-5">
      <b-container>
        <b-row>
          <b-col lg="12" class="text-center text-md-left">
            <h1 class="bigTitle text-center">
              Riduci del 90%<br />i tempi di organizzazione dei tuoi
              Appuntamenti
            </h1>
            <div class="subTitle text-center">
              Con Schedeo le persone prendono appuntamento con te in pochi
              click.
            </div>
            <div style="max-width:800px;" class="m-auto pt-6">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive-item"
                  src="https://www.youtube.com/embed/Wds58UedHmU?controls=0"
                  allowfullscreen
                ></iframe>
              </div>
<!-- <vue-plyr :options={resetOnEnd:true}>
  <div data-plyr-provider="youtube" data-plyr-embed-id="Wds58UedHmU" ></div>
</vue-plyr> -->

            </div>

            <div class="pt-5 pb-6 text-center">
              <base-button
                type="warning"
                style="min-height:5rem;width:100%;"
                @click="goToRegister"
                >Semplifica la Schedulazione dei tuoi Appuntamenti<br />Prova, è
                Gratis</base-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- <section class="bg-gradient-success pt-5">
      <b-container>
        <b-row>
          <b-col lg="5" class="text-center text-md-left">
            <h1 class="bigTitle">
              Riduci del 90%<br/>i tempi di organizzazione dei tuoi Appuntamenti
            </h1>
            <div class="subTitle">
              Con Schedeo le persone prendono
              appuntamento con te in pochi click.
            </div>
            <div class="subTitle pt-5">
              Evita le solite conversazioni:
            </div>
            <VueSlickCarousel v-bind="vueSlickCarouselSettings" class="mt-5">
              <div class="my-bubble-container">
                <div class="my-bubble">
                  <div class="container">
                    <div class="name">Alessandro</div>
                    <div class="text">
                      Facciamo mercoledì?
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-bubble-container">
                <div class="my-bubble alt">
                  <div class="container">
                    <div class="name">Mauro</div>
                    <div class="text">
                      Io Mercoledì non posso...
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-bubble-container">
                <div class="my-bubble">
                  <div class="container">
                    <div class="name">Alessandro</div>
                    <div class="text">
                      E Giovedì mattina?
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-bubble-container">
                <div class="my-bubble alt">
                  <div class="container">
                    <div class="name">Mauro</div>
                    <div class="text">
                      No meglio Venerdì..
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-bubble-container">
                <div class="my-bubble">
                  <div class="container">
                    <div class="name">Alessandro</div>
                    <div class="text">
                      No, son pieno..
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-bubble-container">
                <div class="my-bubble">
                  <div class="container">
                    <div class="name">Alessandro</div>
                    <div class="text">
                      Facciamo Lunedì
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-bubble-container">
                <div class="my-bubble alt">
                  <div class="container">
                    <div class="name">Mauro</div>
                    <div class="text">
                      No non riesco..
                    </div>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            
            <div class="pt-5 pb-6 text-center">
              
              <base-button type="warning" style="min-height:5rem;width:100%;" @click="goToRegister"
                >Semplifica la Schedulazione dei tuoi Appuntamenti<br>Prova, è Gratis</base-button
              >
            </div>
          </b-col>
          <b-col lg="7">
            <b-img src="/img/images/Man_With_Calendar.png" fluid />
          </b-col>
          
        </b-row>
      </b-container>
    </section> -->
    <section class=" pb-5 pt-5">
      <b-container>
        <b-row>
          <b-col>
            <div class="pb-6">
              <h1
                class="text-center text-dark bigTitle"
                style="font-size:3rem;"
              >
                Schedulazioni Automatiche
              </h1>
              <h2 class="text-center">
                Scopri quanto sono comode...<br />Una volta provate non tornerai
                più indietro!
              </h2>
            </div>
          </b-col>
        </b-row>
        <b-row class="pb-6 align-items-center">
          <b-col lg="6" class="text-center text-md-left">
            <h1 class="text-dark">Risparmia Tempo</h1>
            <div>
              <!-- <ul>
                <li>Basta al mandare mail o messaggi con le tue disponibilità e attendere che l'altra persona ti confermi un orario.</li>
                <li>Basta chiamate su chiamate per trovare l'orario che va bene ad entrambi</li>
              </ul> -->
              <!-- Basta al mandare mail o messaggi con le tue disponibilità e attendere che l'altra persona ti confermi un orario.<br><br>
              Basta chiamate su chiamate per trovare l'orario che va bene ad entrambi<br><br>
              Mandi un link e lui sceglie un orario in cui sei libero. -->
              Con Schedeo:
              <ul>
                <li>
                  Non devi mandare mail o messaggi con le tue disponibilità e
                  attendere che l'altra persona ti confermi un orario.
                </li>
                <li>
                  Non devi fare chiamate su chiamate per trovare l'orario che va
                  bene ad entrambi.
                </li>
              </ul>
              Mandi un link e lui sceglie un orario in cui sei libero.
            </div>
          </b-col>
          <b-col lg="6">
            <b-img
              class="pt-4 pt-lg-0"
              src="/img/images/undraw_Digital_nomad_re_w8uyRS.png"
              fluid
            />
          </b-col>
        </b-row>
        <b-row class="pb-6 align-items-center">
          <b-col lg="6" class="order-lg-last text-center text-md-left">
            <h1 class="text-dark">Fai felice anche chi deve prenotare</h1>
            <div>
              Le schedulazioni automatiche sono comode sia per te che per chi
              prenota!<br />
              Semplici, comode e funzionanti 24 ore al giorno!
            </div>
          </b-col>
          <b-col lg="6">
            <b-img src="/img/images/undraw_Happy_news_re_tsbdRS.png" fluid />
          </b-col>
        </b-row>

        <b-row class="pb-8">
          <b-col>
            <div class="text-center pt-5">
              <base-button
                style="width:80%"
                type="warning"
                @click="goToRegister"
                >Prova Subito Schedeo, è Gratis!</base-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section style="background-color:#172b4d;" class="pb-5 pt-5">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h1 class="text-center pb-3 text-white">
              Come Funziona
            </h1>
            <b-card-group deck>
              <card class="border-0" hover shadow body-classes="py-5">
                <div
                  class="icon icon-shape text-white rounded-circle shadow bg-gradient-info mb-4"
                >
                  <i class="ni ni-single-02"></i>
                </div>
                <h4 class="text-primary text-uppercase">
                  1. Definisci le tue disponibilità
                </h4>
                <p class="description mt-3">
                  Inserisci gli orari in cui vuoi essere disponibile ( ci vuole
                  circa 1 Min ), al resto pensa Schedeo.
                </p>
              </card>
              <card class="border-0" hover shadow body-classes="py-5">
                <div
                  class="icon icon-shape text-white rounded-circle shadow bg-gradient-green mb-4"
                >
                  <i class="ni ni-settings"></i>
                </div>
                <h4 class="text-success text-uppercase">
                  2. Condividi il tuo link di prenotazione
                </h4>
                <p class="description mt-3">
                  Invia il tuo link alle persone che devono prendere
                  appuntamento con te.<br />
                  Puoi anche metterlo sul tuo sito!
                </p>
              </card>
              <card class="border-0" hover shadow body-classes="py-5">
                <div
                  class="icon icon-shape text-white rounded-circle shadow bg-gradient-red mb-4"
                >
                  <i class="ni ni-spaceship"></i>
                </div>
                <h4 class="text-warning text-uppercase">
                  3. Ricevi gli Appuntamenti in Automatico
                </h4>
                <p class="description mt-3">
                  Dal tuo link le persone scelgono una data e un'ora e prendono
                  appuntamento con te!
                </p>
              </card>
            </b-card-group>
            <div class="text-center pt-5">
              <base-button
                style="width:80%"
                type="warning"
                @click="goToRegister"
                >Prova Subito Schedeo, è Gratis!</base-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- bg-gradient-success -->
    <section class=" pb-5 pt-5">
      <b-container>
        <!-- <b-row>
          <b-col>
            <h1 class="text-center pb-3 text-dark">
              Funzionalità
            </h1>
          </b-col>
        </b-row> -->
        <b-row class="pb-6 align-items-center">
          <b-col lg="6" class="text-center text-md-left">
            <h1 class="pb-3 text-dark">
              Disponibilità Calcolate in Automatico
            </h1>
            <div>
              Quando qualcuno deve schedulare con te un appuntamento vede le tue
              disponibità in tempo reale.<br />
              Le disponibilità sono calcolate sulla base degli orari che hai
              impostato e degli appuntamenti che hai già nel calendario.
            </div>
          </b-col>
          <b-col lg="6">
            <b-img src="/img/images/undraw_data_processing_yrrv.png" fluid />
          </b-col>
        </b-row>
        <b-row class="pb-6 align-items-center">
          <b-col lg="6" class="order-lg-last text-center text-md-left">
            <h1 class="pb-3 text-dark">Promemoria Automatici</h1>
            <div>
              Chi ha preso appuntamento con te riceve in automatico un
              promemoria.<br />
              Così riduci il numero di persone che si dimenticano!
            </div>
          </b-col>
          <b-col lg="6">
            <b-img
              src="/img/images/undraw_Push_notifications_re_t84m.png"
              fluid
            />
          </b-col>
        </b-row>
        <b-row class="pb-6 align-items-center">
          <b-col lg="6" class="text-center text-md-left">
            <h1 class="pb-3 text-dark">
              Agenda sempre attiva, 24 ore al giorno
            </h1>
            <div>
              I tuoi clienti / colleghi possono schedulare un appuntamento
              quando vogliono, non c'è bisogno che tu sia presente.<br />
              Sistema semplice, rapido e organizzato.
            </div>
          </b-col>
          <b-col lg="6">
            <b-img src="/img/images/undraw_time_management_30iu.png" fluid />
          </b-col>
        </b-row>
        <b-row class="pb-8">
          <b-col>
            <div class="text-center pt-5">
              <base-button
                style="width:80%"
                type="warning"
                @click="goToRegister"
                >Prova Subito Schedeo, è Gratis!</base-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footer class="py-5 px-5 text-white contentFooter" id="footer-main">
      <b-row align-v="center" class="justify-content-lg-between ">
        <b-col xs="6">
          Schedeo.it Srl<br />
          P.Iva: 10787600963
        </b-col>
        <b-col xs="6">
          <b-nav align="center" class="nav-footer justify-content-lg-end">
            <b-nav-item
              href="https://www.schedeo.it/privacy-policy/"
              target="_blank"
            >
              <span class="text-white">
                Privacy Policy
              </span>
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
    </footer>
  </div>
</template>
<style scoped>
.bigTitle {
  font-size: 2rem;
  font-weight: bold;
  line-height: initial;
}
.contentFooter {
  background-color: #172b4d;
  font-size: 0.875rem;
}
.logo {
  width: 200px;
}

h1 {
  color: white;
}

.subTitle {
  color: white;
  font-size: 1.275rem;
  /* font-weight: bold; */
  line-height: initial;
}

.my-bubble-container {
  padding: 0 16px;
  min-height: 80px;
}
.my-bubble {
  background-color: white;
  border-radius: 4px;
  max-width: 240px;
  text-align: left;
  /* display: block !important; */
  position: relative;
}

.my-bubble.alt {
  /* margin: 0 0 0 60px; */
  margin-left: auto;
}

.my-bubble .text {
  font-size: 12px;
  padding: 3px 0;
}

.my-bubble .container {
  padding: 5px 55px 2px 14px;
}

.my-bubble .name {
  font-weight: 600;
  font-size: 12px;
  margin: 0 0 4px;
  color: #3498db;
}

.my-bubble.alt .name {
  color: #f15d22;
}

.my-bubble:after {
  content: "";
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
  transform: rotate(45deg);
  left: -10px;
  top: 10px;
}

.my-bubble.alt:after {
  content: "";
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
  transform: rotate(-45deg);
  left: auto;
  right: -15px;
  top: 10px;
}

/* .my-bubble .bubble-arrow {
  position: absolute;
  width: 0;
  bottom: 42px;
  left: -10px;
  height: 0;
}

.speech-wrapper .bubble .bubble-arrow.alt {
  right: -2px;
  bottom: 40px;
  left: auto;
}

.my-bubble .bubble-arrow:before {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid white;
  border-radius: 0 20px 0;
  width: 15px;
  height: 30px;
  transform: rotate(145deg);
  left: -10px;
  z-index: 9999;
}

.my-bubble.alt .bubble-arrow :before {
  border:none;
  
} */

/********************************************* */
/* .speech-wrapper {
  padding: 30px 40px;
}
.speech-wrapper .bubble {
  width: 240px;
  height: auto;
  display: block;
  background: #f5f5f5;
  border-radius: 4px;
  box-shadow: 2px 8px 5px #000; 
  position: relative;
  margin: 0 0 15px;
}
.speech-wrapper .bubble.alt {
  margin: 0 0 15px 60px;
}
.speech-wrapper .bubble .txt {
  padding: 8px 55px 8px 14px;
}
.speech-wrapper .bubble .txt .name {
  font-weight: 600;
  font-size: 12px;
  margin: 0 0 4px;
  color: #3498db;
}
.speech-wrapper .bubble .txt .name span {
  font-weight: normal;
  color: #b3b3b3;
}
.speech-wrapper .bubble .txt .name.alt {
  color: #2ecc71;
}
.speech-wrapper .bubble .txt .message {
  font-size: 12px;
  margin: 0;
  color: #2b2b2b;
}
.speech-wrapper .bubble .txt .timestamp {
  font-size: 11px;
  position: absolute;
  bottom: 8px;
  right: 10px;
  text-transform: uppercase;
  color: #999;
}
.speech-wrapper .bubble .bubble-arrow {
  position: absolute;
  width: 0;
  bottom: 42px;
  left: -16px;
  height: 0;
}
.speech-wrapper .bubble .bubble-arrow.alt {
  right: -2px;
  bottom: 40px;
  left: auto;
}
.speech-wrapper .bubble .bubble-arrow:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid #f5f5f5;
  border-radius: 0 20px 0;
  width: 15px;
  height: 30px;
  transform: rotate(145deg);
}
.speech-wrapper .bubble .bubble-arrow.alt:after {
  transform: rotate(45deg) scaleY(-1);
} */
</style>

<script>
import router from "/src/routes/router";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "home",
  components: { VueSlickCarousel },
  /**
   * @type {Object}
   */
  data() {
    return {
      vueSlickCarouselSettings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        arrows: false,
        autoplay: true

        // infinite: true,
        // slidesToShow: 4,
        // slidesToScroll: 1,
        // vertical: true,
        // arrows: false,
        // autoplay: false,
      }
    };
  },
  methods: {
    goToRegister() {
      router.push({ name: "register" });
    }
  }
};
</script>
