
























































































































































































































//components
import LogoManager from "@/components/LogoManager.vue";

//models
import { Team, createDefaultTeam } from "@/data/Models/Team/Team";
import {
  TeamInvitation,
  TeamInvitationStatus
} from "@/data/Models/Team/TeamInvitation";

//utils
import Vue, { PropType } from "vue";
import { copyToClipboard } from "@/util/helpers";
import { parseISO } from "date-fns";

//services
import {
  kickMember,
  inviteMember,
  cancelInvite
} from "@/data/services/TeamServices/TeamMemberService";
import {
  getTeamById,
  setLogo,
  removeLogo,
  createTeam,
  saveTeam,
  deleteTeam
} from "@/data/services/TeamServices/TeamService";
import { capitalizeAllFirstLetters } from "@/util/stringHelpers";

export default Vue.extend({
  name: "teams-details",
  components: { LogoManager },
  props: {
    id: {
      type: String,
      default: "CreateTeam"
    }
  },
  data() {
    return {
      team: createDefaultTeam(),
      TeamInvitationStatus: TeamInvitationStatus,
      showInviteMemberModal: false,
      memberToBeInvitedMail: "",
      parseISO: parseISO
    };
  },
  async mounted() {
    await this.loadInterface();
  },
  methods: {
    async loadInterface() {
      if (this.id !== "CreateTeam") this.team = await getTeamById(this.id);
    },
    async onKickMemberClick(userId: number, mail: string) {
      let confirmed = await this.$showConfirmDelete(
        `Stai per rimuovere '${mail}' dal team.<br/>Sicuro di voler procedere?`
      );
      if (confirmed) {
        await kickMember(this.id, userId);
        await this.$showAlertBarSaveSuccessfull();
        await this.loadInterface();
      }
    },
    async onInviteModalCancelClick() {
      this.memberToBeInvitedMail = "";
      this.showInviteMemberModal = false;
    },
    async inviteMember() {
      try {
        await inviteMember(this.id, this.memberToBeInvitedMail);
      } finally {
        this.memberToBeInvitedMail = "";
        this.showInviteMemberModal = false;
        await this.loadInterface();
      }
    },
    copyInvitationLink(teamInvitationId: string) {
      console.log(teamInvitationId);
      const to = {
        name: "team_invitation_management",
        params: {
          teamInvitationId: teamInvitationId
        }
      };
      const relativeUrl = this.$router.resolve(to).href;
      const destinationUrl = `${window.location.protocol}//${window.location.host}${relativeUrl}`;
      copyToClipboard(destinationUrl);

      this.$showAlertBar(
        "Link copiato correttamente, ora invialo all'invitato e digli di accettare l'invito.",
        "success"
      );
    },
    async cancelInvite(mail: string, teamInvitationId: string) {
      let confirmed = await this.$showConfirmDelete(
        `Stai per cancellare l'invito di '${mail}'.<br/><br/>Sicuro di voler procedere?`
      );
      if (confirmed) {
        await cancelInvite(teamInvitationId);
        await this.$showAlertBarSaveSuccessfull();
        await this.loadInterface();
      }
    },
    async setLogo(file: File) {
      await setLogo(file, this.id);
      await this.loadInterface();
    },
    async removeLogo() {
      await removeLogo(this.id);
      await this.loadInterface();
    },
    async createTeam() {
      let validator = this.$refs.myValidator as any;
      let valid = await validator.validate();
      if (valid) {
        let newTeamId = await createTeam(this.team.name, this.team.url);
        this.$router.push({
          name: "team_details",
          params: {
            id: newTeamId
          }
        });
      }
    },
    async saveTeam() {
      let validator = this.$refs.myValidator as any;
      let valid = await validator.validate();
      if (valid) {
        await saveTeam(this.team.id, this.team.name, this.team.url);
        await this.$showAlertBarSaveSuccessfull();
      }
    },
    async deleteTeam() {
      let confirmed = await this.$showConfirmDelete(
        `Stai per cancellare il team '${this.team.name}'.<br/><br/>
        Questo cancellerà anche tutte le pagine di prenotazione di questo Team. ( gli appuntamenti già presi rimarranno invece validi).<br/><br/>
        Sicuro di voler procedere?`
      );
      if (confirmed) {
        await deleteTeam(this.team.id);
        this.$router.push({
          name: "teams"
        });
      }
    },
    setTeamUrl() {
      if (this.team.url === "" && this.team.name !== "") {
        //Se appUrl non è già popolato lo valorizzo sulla base del nome dell'appType

        let str = capitalizeAllFirstLetters(this.team.name);

        //rimuovo tutto ciò che non è permesso e taglio al max a X characters
        str = str.replace(/[^a-zA-Z0-9@.\-+_]/g, "");
        str = str.substring(0, 20);
        this.team.url = str;
      }
    },
    async showHelpTeamUrl() {
      await this.$showHtmlModalMessage(
        "Info",
        `
        Inserisci un nome breve che identifica questo team ( verrà usato nei link di prenotazione di questo team).

        `
      );
    },
    async showHelpPendingInvites() {
      await this.$showHtmlModalMessage(
        "Inviti in Attesa",
        `
          Quando inviti un utente nel team lui riceve una mail e può "accettare" l'invito direttamente dalla mail.<br/><br/>
          Se per qualsiasi ragione non ha ricevuto la mail, puoi cliccare su "Copia Invito" e mandarglielo in altra maniera ( es. Whatsapp ).

          
          `
      );
    },
  },
  computed: {
    pendingTeamInvitations(): TeamInvitation[] {
      return this.team.teamInvitations.filter(
        x =>
          x.status == TeamInvitationStatus.Pending &&
          parseISO(x.expirationDate) > new Date()
      );
    }
  }
});
