import {isValidObject} from "@/util/helpers"

/**
 * 
 * @param {PublicAppointmentTypeDTO} appType 
 */
export function getEnrichedLocationTypes (appType){
    if (isValidObject(appType)) {
        return appType.locationTypes.map(l => {
          switch (l.locationType) {
            case "AddressLocationType":
              l.locationDetails = `Indirizzo: ${l.address}`;
              break;
            case "GoogleMeetLocationType":
              l.locationDetails = `Video Chiamata - Google Meet (riceverai via mail il link)`;

              break;
            case "PhoneLocationType":
              l.locationDetails = `Telefono: ${l.phoneNumber}`;
              break;
          }
          return l;
        });
      }
}