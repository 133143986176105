var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"page-card",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-row',[_c('b-col',{staticClass:"mb-3 d-flex justify-content-between",attrs:{"sm":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{attrs:{"variant":"primary","to":{
                name: 'team_details',
                params: {
                  id: 'CreateTeam'
                }
              }}},[_vm._v("Crea Nuovo Team")])],1),_c('div',[_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.showHelp}},[_vm._v("Istruzioni")])],1)])],1)],1),_c('b-card-body',[_c('b-col',{attrs:{"sm":"12"}},[(_vm.teams.length > 0)?_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"data":_vm.teams,"show-header":false}},[_c('el-table-column',{attrs:{"prop":"name"}}),_c('el-table-column',{attrs:{"align":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('router-link',{attrs:{"to":{ name: 'team_details', params: { id: row.id } }}},[_c('el-button',{attrs:{"size":"mini","type":"text"}},[_vm._v("Modifica")])],1)]}}],null,false,679218010)})],1):_vm._e(),(
            this.teams.length === 0 && this.$store.getters.isLoading === false
          )?_c('div',{staticClass:"pt-5"},[_c('div',{staticClass:"text-center font-weight-bold"},[_vm._v(" Non appartieni a nessun team. Creane uno! ")]),_c('div',{staticClass:"ml-auto mr-auto",staticStyle:{"max-width":"500px"}},[_c('b-img',{attrs:{"src":"/img/images/undraw_empty_street_sfxm.png","fluid":""}})],1)]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }