




































































































































import Vue, { PropType } from "vue";
import { AppTypeSummary } from "@/data/Models/Appointment/AppTypeSummary";
import { discriminatorToItalianType } from "@/data/appointmentService";
import {
  getBookingPageUrl
} from "@/data/appointmentService";
import { copyToClipboard } from "@/util/helpers";
import { cloneDeep, isEqual } from "lodash";

export default Vue.extend({
  name: "app-type-card",
  data() {
    return {
      appTypeSummaryLocal: new Object() as AppTypeSummary,
      discriminatorToItalianType:discriminatorToItalianType
    };
  },
  props: {
    appTypeSummary: {
      type: Object as PropType<AppTypeSummary>
    },
    ownerUrl: {
      type: String
    }
  },
  watch: {
    appTypeSummaryLocal() {
      if (!isEqual(this.appTypeSummaryLocal, this.appTypeSummary)) {
        this.$emit("update:appTypeSummary", this.appTypeSummaryLocal);
      }
    },
    appTypeSummary() {
      this.initLocalData();
    }
  },
  created() {
    this.initLocalData();
  },
  methods: {
    initLocalData() {
      this.appTypeSummaryLocal = cloneDeep(this.appTypeSummary);
    },
    emitAppTypeEnabledStatusChanged(value: boolean) {
      this.$emit("app-type-enabled-status-changed", {
        appTypeId: this.appTypeSummary.id,
        newEnableStatus: value
      });
    },
    async deleteAppointmentType(appTypeSummary: AppTypeSummary) {
      let confirmed = await this.$showConfirmDelete(
        `Stai per cancellare il tipo di appuntamento '${appTypeSummary.name}'.<br/>Sicuro di voler procedere?`
      );
      if (confirmed) {
        this.$emit("app-type-delete-requested", appTypeSummary);
        // await deleteAppointmentType(appType.id, appType.discriminator);
        // await this.$showAlertBarSaveSuccessfull();
      }
    },
    copyBookingUrlToClipboard(appType: AppTypeSummary) {
      let destinationUrl = getBookingPageUrl(
        this.$router,
        this.ownerUrl,
        appType.appUrl,
        window
      );
      copyToClipboard(destinationUrl);
      this.$showAlertBar(
        "Link copiato correttamente, ora invialo a chi deve prenotare ( su Whatsapp, Mail, ecc )",
        "success"
      );
    }
  }
});
