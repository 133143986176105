export interface PublicOwner {
    userUrl:string,
    fullLogoUrl:string,
    name:string,
    //mainBookingPageTitle:string,
    mainBookingPageWelcomeMessage:string
  }

  export function createDefaultPublicOwner(): PublicOwner {
    let defaultPublicOwner: PublicOwner = {
      userUrl: "",
      fullLogoUrl: "",
      name: "",
      mainBookingPageWelcomeMessage: "",
    };
  
    return defaultPublicOwner;
  }