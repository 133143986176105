






























































































































































































































import { cloneDeep } from "lodash";

import Vue, { VueConstructor, PropType } from "vue";
//import "@/data/Models/BillingDetail";
import "@/data/Models/User/IBillingDetail";
import globalMixins from "@/util/mixins/MyGlobalMixins.vue";
import { ValidationProvider } from "vee-validate";
import {
  IBillingDetail,
  createDefaultIBillingDetail,
  CustomerType
} from "@/data/Models/User/IBillingDetail";


export default (Vue as VueConstructor<
  Vue & InstanceType<typeof globalMixins>
>).extend({
  name: "billing-details",
  mixins: [globalMixins],
  props: {
    //externalBillingDetail: { type: [Object, String], default: () => {} }, //FARE PROPTYPE
    externalBillingDetail: {
      type: Object as PropType<IBillingDetail>,
      default: () => {
        return createDefaultIBillingDetail();
      }

      // type: BillingDetail,
      // default: () => {
      //   return new BillingDetail();
      // }
    },
    columnLg: { type: Number, default: 12 },
    mode: { type: String, default: "aggressive" }
  },
  data() {
    return {
      /**
       * @type {BillingDetail}
       */
      // billingDetail: {
      //   userId:"",
      //   companyName: "",
      //   firstName: "",
      //   lastName: "",
      //   fiscalCode: "",
      //   vat: ""
      // }
      billingDetail: createDefaultIBillingDetail()
    };
  },
  created() {
    this.initBillingDetails();
  },
  watch: {
    externalBillingDetail() {
      this.initBillingDetails();
    }
  },
  methods: {
    initBillingDetails() {
      //if (this.isValidObject(this.externalBillingDetail))
      if(this.externalBillingDetail)
        //Creo una copia locale dei dati
        this.billingDetail = cloneDeep(this.externalBillingDetail);
      // else {
      //   //caso in cui non ho ancora billing details
      //   this.billingDetail.userId = this.$store.getters.currentUser.userId;
      //   // this.billingDetail = {
      //   //   userId: this.$store.getters.currentUser.userId,
      //   //   companyName: "",
      //   //   firstName: "",
      //   //   lastName: "",
      //   //   fiscalCode: "",
      //   //   vat: ""
      //   // };
      //   // debugger;
      // }
    },
    /**
     * @description chiamare questo metodo prima di
     * salvare per verificare la correttezza dei dati e visualizzare eventuali errori
     */
    async manualValidation() {
      //let isValid = await this.$refs.billingDetailsValidationObserver.validate();
      debugger;
      let isValid = await (this.$refs
        .billingDetailsValidationObserver as InstanceType<
        typeof ValidationProvider
      >).validate();
      debugger;
      return isValid;
    }
  }
});
