export class User {
    phoneNumber: string;
    userUrl: string;
    //timeSliceSize: number|null;
    logoUrl: string;
    fullLogoUrl: string;
    name: string;
  
  
    constructor() {
     this.phoneNumber = "";
     this.userUrl = "";
     //this.timeSliceSize = null;
     this.logoUrl = "";
     this.fullLogoUrl = "";
     this.name = "";
  
  
    }
  }