























































































































import TimeTableRow from "@/components/TimeTable/TimeTableRow.vue";

import { saveOverrides } from "@/data/availabilitySet";
import { eachDayOfInterval, addMonths } from "date-fns";

import { toIsoDateString } from "@/util/dateManager";
import { getBFormDatePickerItalianLabels } from "@/util/bFormDatePickerHelper.js";
import { DayOverrideDTO } from "@/data/Models/Override/DayOverrideDTO";

import Vue, { VueConstructor } from "vue";
import globalMixins from "@/util/mixins/MyGlobalMixins.vue";
import { ValidationObserver } from "vee-validate";
import { TimeInterval } from "@/data/Models/TimeInterval";

export default //Vue as VueConstructor<
//Vue
//https://forum.vuejs.org/t/using-mixins-with-typescript/24256/17
(Vue as VueConstructor<Vue & InstanceType<typeof globalMixins>>).extend({
  mixins: [globalMixins],
  name: "availability-set-override",
  components: { TimeTableRow },
  data() {
    return {
      uiOverride: {
        sDate: "",
        eDate: "",
        availabilities: [] as TimeInterval[]
      },
      labels: getBFormDatePickerItalianLabels()
    };
  },

  methods: {
    async validate() {
      let result = await (this.$refs.overrideValidator as InstanceType<
        typeof ValidationObserver
      >).validate();
      return result;
    },
    async saveOverrides() {
      let valid = await this.validate();
      if (valid) {
        let daysOverride: Array<DayOverrideDTO> = [];

        let daysBetweenDates = eachDayOfInterval({
          start: new Date(this.uiOverride.sDate),
          end: new Date(this.uiOverride.eDate)
        });

        daysBetweenDates.forEach(day => {
          let dayOverride: DayOverrideDTO = {
            //id: 0,
            availabilitySetId: this.availabilitySetId,
            day: toIsoDateString(day),
            intervals: this.uiOverride.availabilities
          };

          daysOverride.push(dayOverride);
        });
        await saveOverrides(daysOverride);

        this.emitSaved();
      }
    },
    emitCanceled() {
      this.$emit("canceled");
    },
    emitSaved() {
      this.$emit("saved");
    },
    getBFormDatePickerItalianLabels() {
      return getBFormDatePickerItalianLabels();
    },
    onDayTimeTableUpdated(oldData, newData) {
      Object.assign(oldData, newData);
    },
    onIntervalAddRequest() {
      this.uiOverride.availabilities.push({
        startHour: "",
        endHour: ""
      });
    },
    onIntervalRemoveRequest(index:number){
      this.uiOverride.availabilities.splice(index,1);
    }
  },

  props: {
    availabilitySetId: {
      type: Number,
      default: 0
    },
    minSelectableDate: {
      type: Date,
      default: () => {
        return new Date();
      }
    },
    maxSelectableDate: {
      type: Date,
      default: () => {
        return addMonths(new Date(), 6);
      }
    }
  }
});
