var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"page-card",attrs:{"no-body":""}},[_c('b-card-body',[(_vm.currentSubscription != undefined)?_c('b-row',[_c('b-col',{attrs:{"xl":"3","lg":"4"}},[_c('b-card',{staticClass:"text-left mb-3",attrs:{"title":"Piano attuale","header-bg-variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.currentSubscription.plan.planName)+" ")])],1),_c('b-col',{attrs:{"xl":"3","lg":"4"}},[_c('b-card',{staticClass:"text-left mb-3",attrs:{"title":"Costo piano","header-bg-variant":"secondary"}},[(_vm.currentSubscription.plan.basePrice > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.currentSubscription.plan.basePrice))+" + Iva / "+_vm._s(_vm.currentSubscription.plan.duration)+"gg ")]):_c('span',[_vm._v("Gratuito")])])],1),_c('b-col',{attrs:{"xl":"3","lg":"4"}},[_c('b-card',{staticClass:"text-left mb-3",attrs:{"title":_vm.currentSubscription.isAutoRenewActive
                ? 'Prossimo rinnovo'
                : 'Scadenza',"header-bg-variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.formatToDate(_vm.currentSubscription.endDate))+" ")])],1),_c('b-col',{staticClass:"align-self-center text-center",attrs:{"xl":"3","lg":"12"}},[(Date.parse(_vm.currentSubscription.endDate) < Date.now())?_c('div',{staticClass:"pb-3"},[_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){return _vm.goToSubscriptionPayment(_vm.currentSubscription.planId)}}},[_c('span',[_vm._v("Rinnova l'abbonamento")])])],1):_vm._e(),_c('div',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.changeCurrentSubAutoRenewState(
                  !_vm.currentSubscription.isAutoRenewActive
                )}}},[(_vm.currentSubscription.isAutoRenewActive === true)?_c('span',[_vm._v("Cancella rinnovo automatico")]):_c('span',[_vm._v("Attiva rinnovo automatico")])])],1)])],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"text-center pt-5 pb-3"},[_c('h1',[_vm._v("Vuoi passare a un piano diverso?")])])],1),_c('b-row',_vm._l((_vm.activePlans),function(p,index){return _c('b-col',{key:p.id,class:'Plan' + (index + 1) + ' pb-2',attrs:{"lg":"6","xl":"5","offset-xl":index == 0 ? '1' : ''}},[_c('div',{staticClass:"PlanContainer"},[_c('div',{staticClass:"PlanName"},[_vm._v(_vm._s(p.planName))]),_c('div',{staticClass:"PlanPrice"},[(p.basePrice > 0)?_c('span',[_vm._v(_vm._s(p.basePrice)+"€/Mese")]):_c('span',[_vm._v("Free")])]),_c('div',{staticClass:"PlanCTA"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.currentSubscription != undefined &&
                    p.id != _vm.currentSubscription.planId
                ),expression:"\n                  currentSubscription != undefined &&\n                    p.id != currentSubscription.planId\n                "}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.goToSubscriptionPayment(p.id)}}},[_vm._v("Attiva piano")])],1),_c('div',{staticClass:"PlanFeatureContainer text-center"},[_c('div',[_vm._v("Limite appuntamenti: Illimitato")]),_c('div',[_vm._v("Calendari collegabili: "+_vm._s(p.maxActiveCalendars))]),_c('div',[_vm._v(" Pagine di Prenotazione Attive: "),(p.maxActiveAppointmentTypes < 100)?_c('span',[_vm._v(_vm._s(p.maxActiveAppointmentTypes))]):_c('span',[_vm._v("Illimitate")])]),_c('div',[_vm._v("Notifiche Appuntamento: Si")]),_c('div',[_vm._v(" Notifiche Personalizzabili: "+_vm._s(p.hasCustomNotifications ? "Si" : "No")+" ")]),_c('div',[_vm._v(" Promemoria Appuntamenti: "+_vm._s(p.hasAppointmentReminder ? "Si" : "No")+" ")]),_c('div',[_vm._v(" Logo personale pagina di prenotazione: "+_vm._s(p.hasCustomBranding ? "Si" : "No")+" ")]),_c('div',[_vm._v(" Pagine Prenotazione di Team: "+_vm._s(p.hasCustomBranding ? "Si" : "No")+" ")])])])])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }