<template>
  <div>
    <b-card no-body class="page-card">
      <b-card-body>
        <b-row v-if="currentSubscription != undefined">
          <b-col xl="3" lg="4">
            <b-card
              title="Piano attuale"
              class="text-left mb-3"
              header-bg-variant="secondary"
            >
              {{ currentSubscription.plan.planName }}
            </b-card>
          </b-col>
          <b-col xl="3" lg="4">
            <b-card
              title="Costo piano"
              class="text-left mb-3"
              header-bg-variant="secondary"
            >
              <span v-if="currentSubscription.plan.basePrice > 0">
                {{ formatMoney(currentSubscription.plan.basePrice) }} + Iva /
                {{ currentSubscription.plan.duration }}gg
              </span>
              <span v-else>Gratuito</span>
            </b-card></b-col
          >
          <b-col xl="3" lg="4">
            <b-card
              :title="
                currentSubscription.isAutoRenewActive
                  ? 'Prossimo rinnovo'
                  : 'Scadenza'
              "
              class="text-left mb-3"
              header-bg-variant="secondary"
            >
              {{ formatToDate(currentSubscription.endDate) }}
            </b-card>
          </b-col>
          <b-col xl="3" lg="12" class="align-self-center text-center">
            <div
              class="pb-3"
              v-if="Date.parse(currentSubscription.endDate) < Date.now()"
            >
              <b-button
                variant="warning"
                @click="goToSubscriptionPayment(currentSubscription.planId)"
              >
                <span>Rinnova l'abbonamento</span>
              </b-button>
            </div>
            <div>
              <b-button
                variant="secondary"
                @click="
                  changeCurrentSubAutoRenewState(
                    !currentSubscription.isAutoRenewActive
                  )
                "
              >
                <span v-if="currentSubscription.isAutoRenewActive === true"
                  >Cancella rinnovo automatico</span
                >
                <span v-else>Attiva rinnovo automatico</span>
              </b-button>
            </div>

            <!-- <div>
                  cs vanilla: {{currentSubscription.endDate}}<br/>
                  cs parsed: {{new Date(currentSubscription.endDate).toUTCString()}}<br/>
                  date.now: {{Date.now()}} {{new Date(Date.now()).toUTCString()}}<br/>
                </div> -->
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center pt-5 pb-3">
            <h1>Vuoi passare a un piano diverso?</h1>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            v-for="(p, index) in activePlans"
            :key="p.id"
            lg="6"
            xl="5"
            :offset-xl="index == 0 ? '1' : ''"
            :class="'Plan' + (index + 1) + ' pb-2'"
          >
            <div class="PlanContainer">
              <div class="PlanName">{{ p.planName }}</div>
              <div class="PlanPrice">
                <span v-if="p.basePrice > 0">{{ p.basePrice }}€/Mese</span>
                <span v-else>Free</span>
              </div>
              <div class="PlanCTA">
                <b-button
                  v-show="
                    currentSubscription != undefined &&
                      p.id != currentSubscription.planId
                  "
                  variant="primary"
                  @click="goToSubscriptionPayment(p.id)"
                  >Attiva piano</b-button
                >
              </div>
              <div class="PlanFeatureContainer text-center">
                <div>Limite appuntamenti: Illimitato</div>
                <div>Calendari collegabili: {{ p.maxActiveCalendars }}</div>
                <div>
                  Pagine di Prenotazione Attive:
                  <span v-if="p.maxActiveAppointmentTypes < 100">{{
                    p.maxActiveAppointmentTypes
                  }}</span>
                  <span v-else>Illimitate</span>
                </div>
                <div>Notifiche Appuntamento: Si</div>
                <!-- <div>
                    Promemoria Appuntamento:
                    {{ p.hasAppointmentReminder ? "Si" : "No" }}
                  </div> -->
                <div>
                  Notifiche Personalizzabili:
                  {{ p.hasCustomNotifications ? "Si" : "No" }}
                </div>
                <div>
                  Promemoria Appuntamenti:
                  {{ p.hasAppointmentReminder ? "Si" : "No" }}
                </div>
                <div>
                  Logo personale pagina di prenotazione:
                  {{ p.hasCustomBranding ? "Si" : "No" }}
                </div>
                <div>
                  Pagine Prenotazione di Team:
                  {{ p.hasCustomBranding ? "Si" : "No" }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!--  -->
  </div>
</template>

<script>
import router from "../routes/router";
import { getActivePlans } from "@/data/planService.js";
import {
  getCurrentSubscription,
  changeCurrentSubAutoRenewState
} from "@/data/subscriptionService";

export default {
  data() {
    return {
      activePlans: [],
      /**
       * @type {Subscription}
       */
      currentSubscription: undefined
    };
  },
  async mounted() {
    await this.loadActivePlans();
    await this.loadCurrentSubscription();
  },
  methods: {
    goToSubscriptionPayment(selectedPlanId) {
      router.push({
        name: "subscription_payment",
        query: { planId: selectedPlanId }
      });
    },
    async loadActivePlans() {
      this.activePlans = await getActivePlans();
    },
    async loadCurrentSubscription() {
      this.currentSubscription = await getCurrentSubscription();
    },
    async changeCurrentSubAutoRenewState(newState) {
      await changeCurrentSubAutoRenewState(newState);
      await this.loadCurrentSubscription();
    }
  }
};
</script>

<style scoped>
.card-title {
  text-decoration: underline;
}

.current-plan-card-header {
  /*background-color: #f8f9fa;*/
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.PlanContainer {
  border: 1px solid #5dbdea;
  padding: 0;
  background-color: white;
  height: 100%;
  border-radius: 6px;
  /* box-shadow: rgb(51 51 51 / 8%) 2px 2px 14px; */
  margin-bottom: 18px;
  padding-bottom: 18px;
  overflow: hidden;
}

.PlanName {
  text-align: center;
  /* text-transform: uppercase; */
  color: #fff;
  font-size: 1.2rem;
  /* font-weight: bold; */
  padding: 19px 0;
  background: #5dbdea;
  border-radius: 5px 5px 0 0;
  margin-bottom: 20px;
}

.PlanPrice {
  color: #5dbdea;
  font-size: 2rem;
  text-align: center;
  font-weight: 800;
}
.PlanCTA {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fafafa;
  margin-bottom: 20px;
  margin-top: 15px;
  height: 85px;
}

.PlanFeatureContainer > div {
  border-bottom: #eee solid 1px;
  padding: 10px 0;
}

.Plan2 .PlanContainer {
  border-color: #2d639f;
}
.Plan2 .PlanName {
  background-color: #2d639f;
}

.Plan2 .PlanPrice {
  color: #2d639f;
}
</style>
