<template>
  <div class="text-center" style="margin-top:-50px;">
    <b-card>
      <div class="pb-4" style="font-size:2rem;">
        Le prenotazioni automatiche di Schedeo funzionano così:
      </div>
      <b-card-group
        deck
        class="justify-content-center align-items-center align-items-lg-stretch"
      >
        <b-card class="mb-2 hdiw_card">
          <b-card-title style="font-size:2rem;color:#77d188;"
            >1° Step</b-card-title
          >
          <b-card-text>
            Tu invii il link di prenotazione a chi deve prendere appuntamento
          </b-card-text>
        </b-card>
        <i class="fas fa-arrow-right arrow"></i>

        <b-card class="mb-2 hdiw_card">
          <b-card-title style="font-size:2rem;color:#77d188;"
            >2° Step</b-card-title
          >
          <b-card-text
            >La persona sceglie data e ora tra le tue disponibilità</b-card-text
          >
        </b-card>
        <i class="fas fa-arrow-right arrow"></i>

        <b-card class="mb-2 hdiw_card">
          <b-card-title style="font-size:2rem;color:#77d188;"
            >3° Step</b-card-title
          >
          <b-card-text
            >L'appuntamento viene inserito nel tuo calendario esterno
          </b-card-text>
        </b-card>
      </b-card-group>
      <b-row>
        <b-col lg="4"> </b-col>
      </b-row>
      <div class="pt-8 pb-3" style="font-size:2rem;">
        Prova a prendere un appuntamento con te stesso:
      </div>
      <base-button
        style="width:80%"
        type="warning"
        class="mt-2"
        @click="goToIntroBookingPage"
        >Vai alla tua pagina di prenotazione<br />Prova come
        funziona!</base-button
      >
    </b-card>
  </div>
</template>
<script>
import { getUser } from "@/data/account";
import { getPersonalAppointmentTypes } from "@/data/services/AppTypeServices/pesonalAppTypeService";

export default {
  name: "how-does-it-work",
  data() {
    return {
      //introBookingPageUrl: "",
      // introUrlClicked: false
    };
  },
  async beforeRouteLeave(to, from, next) {
    //Siccome il metodo è async, per far catchare l'eccezione devo wrappare in una try catch e chiamare next(errore) per farla funzionare correttamente.
    //https://stackoverflow.com/questions/62045128/why-do-errors-in-vue-router-beforeeach-throw-no-exception-when-running-in-v8js
    try {
      //registro l'evento, e necessario, prima di procedere, controllo.
      if (this.intro === false) {
        next();
      } else {
        //Se sono in intro, allora controllo se ho associato e configurato correttamente dei calendari

        let safeDestinations = ["book", "login"];
        let goingToSafeDestination = safeDestinations.includes(to.name);
        if (goingToSafeDestination) {
          next();
        } else {
          await this.$showModalMessage(
            "Attenzione",
            "Fai una simulazione di appuntamento con te stesso!\r\nClicca sul pulsante arancione!"
          );
        }
      }
    } catch (ex) {
      next(ex);
    }
  },
  methods: {
    async goToIntroBookingPage() {
      let user = await getUser();
      let appTypes = await getPersonalAppointmentTypes();

      let activeAppTypes = appTypes.filter(a => a.isEnabled === true);
debugger;
      this.$router.push({
        name: "book",
        params: {
          ownerUrl: user.userUrl,
          appUrl: activeAppTypes[0].appUrl
        },
        query: {
          intro: true
        }
      });
    }
  }
};
</script>
<style scoped>
.hdiw_card {
  border-color: #77d188;
  max-width: 20rem;
  width: 100%;
}
.card-deck {
  flex-direction: column !important;
}
.arrow {
  font-size: 3rem;
  align-self: center;
  transform: rotate(90deg);
  color: #77d188;
  padding: 5px;
}

@media (min-width: 992px) {
  .arrow {
    font-size: 3rem;
    align-self: center;
    transform: rotate(0deg);
  }
  .card-deck {
    flex-direction: row !important;
  }
}
</style>
