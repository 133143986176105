<template>
    <base-page-layout>
    <template v-slot:pageBody>
        <b-row>
            <b-col class="mb-2">
              <b-button variant="primary">Invita un altro membro</b-button>
            </b-col>
          </b-row>
    </template>
    </base-page-layout>
</template>
<script>

import BasePageLayout from "@/Layout/BasePageLayout.vue";
//import BasePageLayout from '../../Layout/BasePageLayout.vue';

export default {
    components: {
        BasePageLayout
    },
}
</script>
        BasePageLayout