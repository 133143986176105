<template>
  <div
    class="mb-3 px-4"
    style="background-color: #efefef; border-radius: 10px; padding: 10px;border: 1px solid #e4e3e3;"
  >
    <div class="py-2" style="border-bottom: 1px solid silver">

      <b-row class="align-items-center">
        <b-col>
          <strong>{{ calendarAccount.accountName }}</strong>
        </b-col>
        <b-col class="text-right">
          <b-button
            variant="link"
            @click="deleteCalendarAccount(calendarAccount.id)"
          >
            Scollega account
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- <b-table :items="calendarAccount.calendars" :fields="fields">
      <template #cell(trackConflict)="data">
        <b-form-checkbox v-model="data.item.trackConflicts"> </b-form-checkbox>
      </template>
      <template #cell(addToCalendar)="data">
        <b-form-checkbox v-model="data.item.addToCalendar"> </b-form-checkbox>
      </template>
     
    </b-table> -->
    <div class="pt-3">
      <div class="pb-2">Calendari</div>
      <div
        v-for="calendar in calendarAccount.calendars"
        :key="getBuiltKey(calendarAccount, calendar)"
        class="pl-3 pb-3"
      >
        <div>
          <strong>{{ calendar.calendarName }}</strong>
        </div>
        <div class="pl-3">
          <div>
            <div class="d-inline-block calendarOptionsLabelDiv">Traccia Conflitti:</div>
            <b-form-checkbox
              class="ml-2 d-inline"
              v-model="calendar.trackConflicts"
            ></b-form-checkbox>
          </div>
          <div>
            
            <div class="d-inline-block calendarOptionsLabelDiv">Aggiungi Eventi:</div>
            <b-form-checkbox
              class="ml-2 d-inline"
              v-model="calendar.addToCalendar"
            ></b-form-checkbox>
            
          </div>
        </div>
      </div>
      <!-- <b-row v-for="calendar in calendarAccount.calendars" :key="calendar.id">
        <b-col>{{calendar.calendarName}}</b-col>
        <b-col>Traccia Conflitti: <b-form-checkbox v-model="calendar.trackConflicts"></b-form-checkbox></b-col>
        <b-col>Aggiungi Eventi: <b-form-checkbox v-model="calendar.addToCalendar"></b-form-checkbox></b-col>
      </b-row> -->
    </div>
  </div>
</template>
<script>
import * as calendarAccount from "@/data/calendarAccount";
export default {
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    
    calendarAccount: { type: Object, default: ()=>{} },
  },
  methods: {
    async deleteCalendarAccount(id) {
      let result = await calendarAccount.deleteCalendarAccount(id);
      this.$emit("deleted-calendar-account");
    },
    getBuiltKey(calendarAccount, calendar) {
      return `${calendarAccount.accountProvider}_${calendarAccount.accountName}_${calendar.externalId}`;
      //return `${calendar.externalId}_12123`;
    },
  },
};
</script>
<style scoped>
.calendarOptionsLabelDiv{
width:125px;
}
</style>
