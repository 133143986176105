






























































































































































































































































































































import { getSafe } from "@/util/helpers";
import * as availability from "@/data/availability.js";
import { addEvent } from "@/data/event.js";
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { schedeoFormatDate, getDaysInMonth } from "@/util/dateManager";
import {
  addDays,
  addMonths,
  startOfMonth,
  endOfMonth,
  differenceInMinutes,
  startOfDay
} from "date-fns";
getPublicProfileFromOwnerUrl;
import {
  getPublicAppointmentTypeInfoFromUrl,
  getPublicProfileFromOwnerUrl
} from "@/data/appointmentService";

import Vue, { VueConstructor, PropType } from "vue";
import globalMixins from "@/util/mixins/MyGlobalMixins.vue";

import { v4 as uuidv4 } from "uuid";
import { getEnrichedLocationTypes } from "@/util/locationTypeHelper";

//Components
import SchedeoCalendar from "@/components/SchedeoCalendar.vue";
import LogoComponent from "@/components/LogoComponent.vue";
import TypeEffect from "@/components/TypeEffect.vue";

//typedef
import "@/data/Models/PublicUserDTO";
import "@/data/Models/TimeRangeDTO";
import {
  PublicOwner,
  createDefaultPublicOwner
} from "@/data/Models/User/PublicOwner";
import { TimeRange } from "@/data/Models/TimeRange";
import {
  PublicAppointmentType,
  createDefaultPublicAppointmentType
} from "@/data/Models/Appointment/PublicAppointmentType";
import { ValidationObserver } from "vee-validate";

//export default //mixins(globalMixin).extend (
export default (Vue as VueConstructor<
  Vue & InstanceType<typeof globalMixins>
>).extend({
  name: "BookPage",
  data() {
    return {
      currentIntroMessage: "",
      hasIntroContainerPulse: true,
      bookingPageProblem: "",
      showIntroSavedModalId: "",
      schedeoFormatDate,
      differenceInMinutes: differenceInMinutes,
      daysWithAvailability: new Array<{date:Date,isAvailable:Boolean}>(),
      //daysWithAvailability: [],
      /**
       * @type {TimeRangeDTO[]}
       */
      availableDaySlots: new Array<TimeRange>(),
      selectedDay: {} as any,
      selectedTimeslot: {},
      event: {
        ownerUrl: this.ownerUrl,
        start: new Date(),
        end: new Date(),
        //summary: "",
        //description: "",
        applicantName: "",
        applicantPhone: "",
        applicantMail: "",
        notes: "",
        appUrl: this.appUrl,
        acceptPolicies: false,
        locationId: -1
      },
      publicOwner: createDefaultPublicOwner(),
      /**
       * @type {PublicAppointmentTypeDTO}
       */
      appType: createDefaultPublicAppointmentType()
    };
  },
  components: {
    /**
     * @type {object}
     */
    FormWizard,
    /**
     * @type {object}
     */
    TabContent,
    WizardButton,
    SchedeoCalendar,
    LogoComponent,
    TypeEffect
  },

  props: {
    ownerUrl: {
      type: String,
      default: ""
    },
    appUrl: {
      type: String,
      default: ""
    },

    intro: {
      //Se questo è a true significa che devo gestire l'intro per i nuovi iscritti ( messaggi particolari, ecc)
      type: Boolean,
      default: false
    }
  },

  async mounted() {
    await this.loadInterface();
  },
  computed: {
    enrichedLocationTypes(): any[] {
      return getEnrichedLocationTypes(this.appType);
    }
  },
  methods: {
    async loadInterface() {
      try {
        await this.loadDaysWithAvailability();
        this.publicOwner = await getPublicProfileFromOwnerUrl(this.ownerUrl);
        this.appType = await getPublicAppointmentTypeInfoFromUrl(
          this.ownerUrl,
          this.appUrl
        );
        if (this.appType.locationTypes.length === 1) {
          //Se ho solo una location seleziono quella.
          this.event.locationId = this.appType.locationTypes[0].id;
        }

        if (this.intro === true) {
          this.loadIntroData();
          await this.showIntroStart();
        }
      } catch (ex:any) {
        let a = ex;
        let errorCode = getSafe(() => ex.response.data.errorCode);
        let safeMessage = getSafe(() => ex.response.data.safeMessage);
        //Se ho un problema noto sulla booking page lo evidenzio, altrimenti throwo
        debugger;
        switch (errorCode) {
          
          case "UserUrlNotFound":
            this.$router.push({ name: "not-found" });
            break;
          case "AppTypeDisabled":
            this.bookingPageProblem = safeMessage;
            break;
            case "AppTypeNotFound":
            this.bookingPageProblem = safeMessage;
            break;
          default:
            throw ex;
        }
      }
    },

    onDaySelected(day) {
      this.selectedDay = day;

      let wizard: any = this.$refs.wizard;
      wizard.nextTab();
    },

    // async onIntroSavedModalHidden(bvEvent, modalId) {
    //   if (modalId === this.showIntroSavedModalId)
    //     await this.$router.push({
    //       name: "getting_started"
    //     });
    // },
    async showIntroStart() {
      let msg =
        "Questa è la pagina che vedranno le persone a cui invierai il tuo link di prenotazione.\r\nCliccando su un giorno verde vedranno quando sei disponibile.";
      //await this.$showTimedModalMessage("Simulazione di prenotazione", msg, 0);
      this.currentIntroMessage = msg;
    },
    async showIntroConfirmation() {
      let msg = "Questo è il riepilogo dell'appuntamento.\r\n";
      msg += 'Premi su "Conferma" per terminare la prova.';
      //await this.$showModalMessage("Conferma dell'appuntamento ", msg);
      this.currentIntroMessage = msg;
    },
    async showIntroYourTimeSlots() {
      let msg =
        "Queste sono le tue disponibilità reali, verranno calcolate in base agli appuntamenti che hai già preso.\r\nScegli un orario per continuare.";
      //await this.$showTimedModalMessage("Disponibilità Reali", msg, 0);
      this.currentIntroMessage = msg;
    },
    async showIntroCustomerDataFilled() {
      let msg =
        'Normalmente qui "chi prenota" inserisce i suoi dati.\r\nI dati non sono modificabili perchè è una simulazione.\r\nPremi avanti per continuare.';

      //await this.$showTimedModalMessage("Dati di esempio", msg, 0);
      this.currentIntroMessage = msg;
    },

    async showIntroSavedModal() {
      let msg =
        "L'appuntamento è stato inserito correttamente.\r\n Ora ti manderemo alla pagina che riepiloga i tuoi appuntamenti.";

      //await this.$showTimedModalMessage("Fine Simulazione", msg, 0);
      this.currentIntroMessage = msg;
    },
    loadIntroData() {
      this.event.applicantName = "Mario Rossi";
      this.event.applicantPhone = "123456789";
      this.event.applicantMail = "test.appuntamenti@schedeo.it";
      this.event.notes = "Appuntamento di prova";
      this.event.acceptPolicies = true;

      if (this.appType.locationTypes.length > 0)
        this.event.locationId = this.appType.locationTypes[0].id;
    },
    async validateData() {
      let valObserver = this.$refs.bookValObserver as InstanceType<
        typeof ValidationObserver
      >;

      //let isValid = await this.$refs.bookValObserver.validate();
      let isValid = await valObserver.validate();
      if (isValid) return true;
      else return "Controllare i campi";
    },
    async onStepChange(prevIndex, nextIndex) {
      //Mi assicuro di essere in book perchè al router.push viene raisato un altro onStepChange (non chiaro il motivo)
      //uso include perchè durante lo sviluppo avevo book e book2
      //if (this.$router.currentRoute.name.includes(this.$options.name)) {
      console.log("book");
      switch (nextIndex) {
        case 0:
          await this.showIntroStart();
          break;
        case 1: //timeSlots
          this.loadTimeSlots();
          if (this.intro === true) {
            await this.showIntroYourTimeSlots();
          }
          break;
        case 2: //CustomerData
          if (this.intro === true) {
            await this.showIntroCustomerDataFilled();
          }
          break;
        case 3: //Confirmation
          if (this.intro === true) {
            await this.showIntroConfirmation();
          }
          break;
      }
      //}
    },

    isDate(checkDate) {
      return checkDate instanceof Date;
    },
    async loadDaysWithAvailability() {
      let schedeoCalendar = this.$refs.schedeoCalendar as any;
      this.daysWithAvailability = await this.getDaysWithAvailability(
        schedeoCalendar.currentYear,
        schedeoCalendar.currentMonth,
        this.ownerUrl,
        this.appUrl
      );
    },
    async getDaysWithAvailability(year, month, ownerUrl, appUrl) {
      let currentCalendarDays = getDaysInMonth(year, month);

      let sDate = new Date(year, month - 1);
      let eDate = addMonths(new Date(sDate), 1);

      let availabilities = await availability.GetAppointmentTypeAvailabilities(
        sDate,
        eDate,
        ownerUrl,
        appUrl
      );

      //let daysWithAvailability = [];
      let daysWithAvailability = new Array<{date:Date,isAvailable:Boolean}>();

      currentCalendarDays.forEach(element => {
        let currentElementDate = new Date(
          element.year,
          element.month - 1,
          element.day
        );

        let dayAvailabilities = availabilities.filter(
          d =>
            d.start.getDate() == element.day &&
            d.start.getMonth() + 1 == element.month &&
            d.start.getFullYear() == element.year
        );

        daysWithAvailability.push({
          date: currentElementDate,
          isAvailable: dayAvailabilities.length > 0
        });
      });

      return daysWithAvailability;
    },
    async loadTimeSlots() {
      let day = this.selectedDay;
      let sDate = new Date(day.year, day.month - 1, day.day);
      let eDate = addDays(new Date(sDate), 1);

      this.availableDaySlots = await availability.GetAppointmentTypeAvailabilities(
        sDate,
        eDate,
        this.ownerUrl,
        this.appUrl
      );
    },
    async onTimeslotClick(timeslot) {
      this.selectedTimeslot = timeslot.start;
      this.event.start = timeslot.start;
      this.event.end = timeslot.end;

      //this.$refs.wizard.nextTab();
      let wizard: any = this.$refs.wizard;
      wizard.nextTab();
    },
    async onCalendarMonthMove(page) {
      await this.loadDaysWithAvailability();
    },

    async addEvent() {
      let wizard: any = this.$refs.wizard;
      

      try {
        await addEvent(this.event);
        if (this.intro === true) {
          await this.$router.push({
            name: "availabilities"
          });
          return;
        }
      } catch (ex:any) {
        if (ex.response.data.errorCode === "TimeSlotNotAvailable") {
          //show message and go back to start
          this.$showAlert(ex.response.data.safeMessage);
          await this.loadDaysWithAvailability();
          //this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);

          wizard.changeTab(wizard.activeTabIndex, 0);
        } else {
          throw ex;
        }
      }
      //this.$refs.wizard.nextTab();
      wizard.nextTab();
    }
  }
  // watch: {
  //   currentIntroMessage() {
  //     this.hasIntroContainerPulse= !this.hasIntroContainerPulse;
  //   }
  // }
});
//)
