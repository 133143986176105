export enum TeamInvitationStatus {
    Pending="Pending",
    Accepted="Accepted",
    Refused="Refused",
    Canceled="Canceled",
  }

export interface TeamInvitation {
    id: string;
    teamId: string;
    senderUserId: number;
    receiverUserId: number;
    creationDate: string;
    expirationDate: string;
    status: TeamInvitationStatus;
    acceptanceDate: string | null;
    receiverUserMail: string;
  }