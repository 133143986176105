<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container class="container">
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Crea il tuo <br />Account Gratuito</h1>
              <p class="text-lead text-white">
                Creando l'account potrai accedere alle funzionalità di Schedeo
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <!-- Table -->
      <b-row class="justify-content-center">
        <b-col lg="6" md="8">
          <b-card no-body class="bg-secondary border-0">
            <!-- <b-card-header class="bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-4"><small>Sign up with</small></div>
              <div class="text-center">
                <a href="#" class="btn btn-neutral btn-icon mr-4">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <!-- <small>Inserisci la mail con cui vuoi registrarti e una password</small> -->
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-single-02"
                    label="Nome"
                    placeholder="Nome"
                    name="Nome"
                    :rules="{ required: true }"
                    v-model="model.name"
                  >
                  </base-input>

                  <!-- <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="fas fa-phone"
                    label="Telefono"
                    placeholder="Telefono"
                    name="Telefono"
                    type="phone"
                    :rules="{ required: true }"
                    v-model="model.phone"
                  >
                  </base-input> -->

                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-email-83"
                    label="Mail"
                    placeholder="Mail"
                    name="Mail"
                    :rules="{ required: true, email: true }"
                    v-model="model.email"
                    type="text"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-lock-circle-open"
                    label="Password"
                    placeholder="password"
                    type="password"
                    name="Password"
                    :rules="{ required: true, min: 8 }"
                    v-model="model.password"
                    autocomplete="new-password"
                  >
                  </base-input>
                  <!-- <div class="text-muted font-italic"><small>password strength: <span
                    class="text-success font-weight-700">strong</span></small></div> -->
                  <b-row class="my-4">
                    <b-col cols="12">
                      <base-input
                        :rules="{ required: { allowFalse: false } }"
                        name="Accettazione"
                        Policy
                      >
                        <b-form-checkbox v-model="model.agree">
                          <span class="text-muted"
                            >Accetto le
                            <a
                              href="https://www.schedeo.it/privacy-policy/"
                              target="_blank"
                              >Privacy Policy</a
                            >
                            e le
                            <a
                              href="https://www.schedeo.it/condizioni-dutilizzo/"
                              >Condizioni d'utilizzo</a
                            ></span
                          >
                        </b-form-checkbox>
                      </base-input>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <b-button type="submit" variant="primary" class="mt-4"
                      >Crea il tuo Account Gratuito</b-button
                    >
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import * as account from "@/data/account";
//import router from "../routes/router";
import router from "@/routes/router";

export default {
  name: "register",
  data() {
    return {
      model: {
        name: "",
        email: "",
        password: "",
        phone: "",
        agree: false,

        utm_source: this.$utm.utm_source,
        utm_campaign: this.$utm.utm_campaign,
        utm_content: this.$utm.utm_content,
        utm_campaignId: this.$utm.utm_campaignId,
        utm_adGroupId: this.$utm.utm_adGroupId,
        utm_keyword: this.$utm.utm_keyword,
        utm_matchType: this.$utm.utm_matchType
      }
    };
  },
  methods: {
    async onSubmit() {
      await account.register(this.model);
      
      //Creo il token e loggo
      await this.$store.dispatch("createToken", {
        email: this.model.email,
        password: this.model.password
      });

      //redirecto alla pagina giusta
      this.$router.push({ name: "thank_you" });
    }
  }
};
</script>
<style></style>
