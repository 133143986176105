<template>
  <div>
    <validation-observer ref="bookValObserver">
      <b-container style="padding-top: 10px">
        <b-row>
          <b-col>
            <b-card no-body>
              <div>
                <b-card-header>
                  <b-row>
                    <b-col>
                      <a
                        v-if="
                        this.publicOwner!=undefined &&
                          isValidObject(this.publicOwner) &&
                            !this.publicOwner.fullLogoUrl
                        "
                        href="https://www.schedeo.it"
                        target="_blank"
                      >
                        <div class="text-center">
                          <div style="font-size: 0.9rem">Powered by</div>
                          <img
                            src="/img/brand/Logo-WhiteSmallRect.jpg"
                            class="navbar-brand-img"
                            alt="..."
                            style="width: 160px; padding-bottom: 20px"
                          />
                        </div>
                      </a>
                      <div v-else class="text-center pb-3">
                        <img
                          class="custom-logo"
                          :src="this.publicOwner.fullLogoUrl"
                        />
                      </div>
                      <div class="text-center">
                        <h2 class="pb-2">Cancellazione Appuntamento</h2>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <div v-if="isValidObject(event) && !isEventCancelled" style="max-width:400px;" class="m-auto">
                    <div>{{ this.publicOwner.name }}</div>
                    <div>
                      <div>
                        {{ schedeoFormatDate(event.startDate, "PPPP") }}
                        alle {{ schedeoFormatDate(event.startDate, "p") }}
                      </div>
                    </div>
                    <b-form-group>
                      <label class="form-control-label pt-3">
                        Motivo della cancellazione
                      </label>
                      <b-form-textarea
                        v-model="cancellationReason"
                      ></b-form-textarea>
                    </b-form-group>
                    <div class="text-center">
                    <b-button
                      href="javascript:;"
                      variant="button"
                      class="btn-primary"
                      @click="cancelSingleAttendeeEvent"
                      >Annulla Appuntamento</b-button
                    >
                  </div>
                  </div>
                  <div v-if="isValidObject(event) && isEventCancelled">
                    
                 
                    <div style="max-width:250px;" class="ml-auto mr-auto pt-3">
                      <b-img
                        src="/img/images/undraw_done_re_oak4.png"
                        fluid
                      />
                    </div>
                    <div class="text-center no-appointment pt-3">
                      Appuntamento cancellato.
                    </div>
                  </div>
                  
                </b-card-body>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </validation-observer>
  </div>
</template>

<script>
//import { isSameDay } from "date-fns";
import "@/data/Models/SingleAttendeeEventDTO";


import { schedeoFormatDate } from "@/util/dateManager";
import { getPublicProfileFromEventId } from "@/data/account";


import {
  getSingleAttendeeEvent,
  cancelSingleAttendeeEvent
} from "@/data/event.js";

export default {
  name: "cancel",
  data() {
    return {
      schedeoFormatDate,
      /**
       * @type {PublicUserDTO}
       */
      publicOwner: {name:"",fullLogoUrl:"",Url:""},
      /**
       * @type {SingleAttendeeEventDTO}
       */
      event: {},
      cancellationReason: "",
      isEventCancelled: false,
      
    };
  },
  props: {
    eventId: {
      type: String,
      default: ""
    }
  },
  methods: {
    async cancelSingleAttendeeEvent() {

let confirmed = await this.$showConfirmDelete(
        `Stai per annullare l'appuntamento.<br/>Sicuro di voler procedere?`
      );

      if (!confirmed) return;

      await cancelSingleAttendeeEvent({
        eventId: this.eventId,
        cancellationReason: this.cancellationReason
      });

      this.isEventCancelled = true;
    }
  },

  async mounted() {
    try
    {
    this.publicOwner = await getPublicProfileFromEventId(this.eventId);
    }
    catch(ex){
      //Se non riesco a recuperare il public owner pazienza, non faccio vedere nome e logo.
    }
    this.event = await getSingleAttendeeEvent(this.eventId);

    //se l'evento risulta cancellato, setto già il flag a cancelled
    this.isEventCancelled = this.event.status ==="Canceled";
  }
};
</script>

<style scoped>
.custom-logo {
  max-width: 200px;
  max-height: 200px;
}
</style>
