









































































































































import Vue, { VueConstructor } from "vue";
import { getUser, insertUserObjective } from "@/data/account";
import { getPersonalAppointmentTypes } from "@/data/services/AppTypeServices/pesonalAppTypeService";

// declare global {
//   interface Window {
//     dataLayer: any[];
//   }
// }

export default Vue.extend({
  name: "thank-you",
  data() {
    return {
      visibilityChoices: {
        showQuestionsDiv: true,
        showBookingPageAnswer: false,
        showJustAgendaAnswer: false,
        showOtherAnswer: false,
        showDoYouWannaTryBookingPage: false
      },
      customerObjective: "" as string,
      introEnded: false
    };
  },
  mounted() {
    if (this.$gtm.enabled() && window.dataLayer) {
      //non controllo il cookie perchè il gtm al momento è attivo solo se il cookie è stato accettato.
      window.dataLayer.push({
        event: "nuovoPartnerRegistrato",
        leadInfo: {
          conversionValue: 1.0,
          userId: this.$store.state.currentUser.userId
        } // further parameters
      });
    }
  },

  async beforeRouteLeave(to, from, next) {
    //Siccome il metodo è async, per far catchare l'eccezione devo wrappare in una try catch e chiamare next(errore) per farla funzionare correttamente.
    //https://stackoverflow.com/questions/62045128/why-do-errors-in-vue-router-beforeeach-throw-no-exception-when-running-in-v8js
    try {
      let safeDestinations = ["book", "login"];

      let goingToSafeDestination = safeDestinations.includes(to.name!);
      if (goingToSafeDestination || this.introEnded) {
        next();
      } else {
        await this.$showModalMessage(
          "Attenzione",
          "Fai una simulazione di appuntamento con te stesso!\r\nClicca sul pulsante arancione!"
        );
      }
    } catch (ex) {
      next(ex);
    }
  },
  methods: {
    resetVisibilityChoices() {
      this.visibilityChoices.showQuestionsDiv = false;
      this.visibilityChoices.showBookingPageAnswer = false;
      this.visibilityChoices.showJustAgendaAnswer = false;
      this.visibilityChoices.showOtherAnswer = false;
      this.visibilityChoices.showDoYouWannaTryBookingPage = false;
    },
    async choiceClicked(target: HTMLButtonElement) {
      this.resetVisibilityChoices();
      let choosenValue = target.attributes["data-choosen-value"].value;
      this.visibilityChoices[choosenValue] = true;

      if (choosenValue != "showOtherAnswer") {
        this.customerObjective = target.innerText;
        await insertUserObjective(this.customerObjective);
        this.introEnded = true;
      }
    },
    async goToIntroBookingPage() {
      let user = await getUser();
      debugger;
      let appTypes = await getPersonalAppointmentTypes();

      debugger;
      let activeAppTypes = appTypes.filter(a => a.isEnabled === true);

      this.$router.push({
        name: "book",
        params: {
          ownerUrl: user.userUrl,
          appUrl: activeAppTypes[0].appUrl
        },
        query: {
          intro: "true"
        }
      });
    },
    async saveObjectiveAndProceedClicked() {
      await insertUserObjective(`Altro: ${this.customerObjective}`);
      this.resetVisibilityChoices();
      this.visibilityChoices.showDoYouWannaTryBookingPage = true;
      this.introEnded = true;
    },
    goToAppointmentTypes() {
      this.$router.push({ name: "appointment_types" });
    }
  }
});
