import apiClient from "../util/ApiClient";
import "@/data/Models/Event/GetEventsRequest";
import "@/data/Models/Event/Event_Get_DTO";
import "@/data/Models/PagedListDTO.js";
import "@/data/Models/SingleAttendeeEventRescheduleDTO.js";

export const addEvent = async function(event) {
  return apiClient.post("Event/AddEvent", event);
};

/**
 * @param {GetEventsRequest} getEventsRequest
 * @returns {Promise<PagedListDTO<Event_Get_DTO>>}
 *
 */

export const getEvents = async function(getEventsRequest) {
  let result = await apiClient.get("Event/GetEvents", {
    params: {
      startDate: getEventsRequest.startDate,
      endDate: getEventsRequest.endDate,
      pageNumber: getEventsRequest.pageNumber,
      pageSize: getEventsRequest.pageSize,
      sort: getEventsRequest.sort,
      searchText: getEventsRequest.searchText
    }
  });
  return result.data;
};

/**
 * @param {string} eventId
 * @returns {Promise<SingleAttendeeEventDTO>}
 *
 */
export const getSingleAttendeeEvent = async function(eventId) {
  let result = await apiClient.get("Event/GetSingleAttendeeEvent", {
    params: { eventId: eventId }
  });
  return result.data;
};

export const rescheduleSingleAttendeeEvent = async function(event) {
  //In questo momento posto più roba del dovuto ma non fixo perchè devo andare veloce e comunque lato server gestisco solo i dati
  // che mi interessano. Appena rimetto mano qui fixare.
  return apiClient.post("Event/RescheduleSingleAttendeeEvent", event);
};

export const cancelSingleAttendeeEvent = async function(singleAttendeeEventCancelRequestDTO) {
  return apiClient.post("Event/CancelSingleAttendeeEvent", singleAttendeeEventCancelRequestDTO);
};

