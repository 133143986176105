<template>
  <div>
    <b-card no-body class="tabbed-page-card">
      <b-tabs card >
        <b-tab title="Orari">
          <div>
            <div class="text-right pb-4">
              <b-button variant="link" @click="showAvailabilitiesHelp">Istruzioni</b-button>
            </div>
            <div>
              <time-table :id="id" :showTemplates="false" />
            </div>
          </div>
        </b-tab>
        <b-tab title="Orari sostitutivi" v-if="this.id>0">
          <div>
            <div class="d-flex justify-content-between grey-bottom-line pb-4">
              <b-button variant="primary" @click="showOverrideModal"
                >Crea nuovo Orario Sostitutivo</b-button
              >
              <b-button variant="link" @click="showOverridesHelp"
                >Istruzioni</b-button
              >
            </div>
            <availability-set-override-table
              v-if="overrides.length > 0"
              :availabilitySetId="id"
              :overrides="overrides"
              @deleteOverride="deleteOverride"
            />
            <b-modal ref="override-modal" :hide-footer="true">
              <availability-set-override
                :availability-set-id="id"
                @canceled="hideOverrideModal"
                @saved="onOverrideSaved"
              />
            </b-modal>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import TimeTable from "@/components/TimeTable/TimeTable.vue";

//Sono arrivato qui
import AvailabilitySetOverrideTable from "@/components/AvailabilitySetOverrideTable.vue";
import AvailabilitySetOverride from "@/components/AvailabilitySetOverride.vue";

import {
  getAvailabilitySetOverrides,
  deleteOverride
} from "@/data/availabilitySet";

export default {
  name: "Availability",
  components: {
    TimeTable,
    AvailabilitySetOverrideTable,
    AvailabilitySetOverride
  },
  data() {
    return {
      /**
       * @type {Array<DayOverrideDTO>}
       */
      overrides: []
      //overrideId: 0
    };
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  async mounted() {
    await this.loadOverrides();
  },
  methods: {
    showOverrideModal() {
      //this.overrideId = overrideId;
      this.$refs["override-modal"].show();
    },
    hideOverrideModal() {
      this.$refs["override-modal"].hide();
    },
    async onOverrideSaved() {
      this.$refs["override-modal"].hide();
      await this.loadOverrides();

      await this.$showAlertBarSaveSuccessfull();
    },
    async loadOverrides() {
      this.overrides = await getAvailabilitySetOverrides(
        this.id,
        new Date(),
        new Date(2099, 1, 1)
      );
    },
    async deleteOverride(sDate,eDate) {
      await deleteOverride(this.id, sDate,eDate);
      await this.$showAlertBarSaveSuccessfull();
      await this.loadOverrides();
    },
    async showOverridesHelp() {
      await this.$showHtmlModalMessage(
        "Orari Sostitutivi",
        `Servono per gestire delle variazioni rispetto all'orario normale, ad esempio:<br/><br/>
        <b>Ferie:</b><br>
        Se devi fare una settimana di ferie puoi creare un orario sostitutivo, inserire dal giorno X al giorno Y e poi non mettere nessun orario, in quel modo non risulterai disponibile.
        <br>
        <br>
        <b>Orario ridotto:</b><br> Se normalmente sei disponibile tutto il giorno ma per un periodo vuoi esserlo solo la mattina, ti basta creare un orario sostitutivo e selezionare inserire che dal giorno X al giorno Y sarai disponibile in quegli orari
        <br/>
        <br/>


        `
      );
    },
  async showAvailabilitiesHelp() {
      await this.$showHtmlModalMessage(
        "Disponibilità",
        `Le disponibilità sono gli orari in cui sei disponibile.<br><br>
        Quando ha creato la disponibilità la devi associare a uno o più tipi di appuntamento (nella sezione Tipi di Appuntamento).
        <br/>
        <br/>
        `
      );
    }
  }
};
</script>

<style></style>
