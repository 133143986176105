





























































































































































































































































































































import * as availability from "@/data/availability.js";
import {
  rescheduleSingleAttendeeEvent,
  getSingleAttendeeEvent
} from "@/data/event.js";
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { schedeoFormatDate, getDaysInMonth } from "@/util/dateManager";
import {
  addDays,
  addMonths,
  startOfMonth,
  endOfMonth,
  differenceInMinutes,
  startOfDay
} from "date-fns";
import { getPublicProfileFromEventId } from "@/data/account";
import { getPublicAppointmentTypeInfoFromEventId } from "@/data/appointmentService";
import { getEnrichedLocationTypes } from "@/util/locationTypeHelper";

//Components
import SchedeoCalendar from "@/components/SchedeoCalendar.vue";
import LogoComponent from "@/components/LogoComponent.vue";

//typedef
import "@/data/Models/PublicUserDTO";
import "@/data/Models/TimeRangeDTO";
import {
  PublicOwner,
  createDefaultPublicOwner
} from "@/data/Models/User/PublicOwner";

import {
  PublicAppointmentType,
  createDefaultPublicAppointmentType
} from "@/data/Models/Appointment/PublicAppointmentType";
import { TimeRange } from "@/data/Models/TimeRange";

import Vue, { VueConstructor, PropType } from "vue";
import globalMixins from "@/util/mixins/MyGlobalMixins.vue";

import { ValidationObserver } from "vee-validate";

//export default {
export default (Vue as VueConstructor<
  Vue & InstanceType<typeof globalMixins>
>).extend({
  name: "reschedule",
  data() {
    return {
      /**
       * @type {SingleAttendeeEventDTO}
       */
      formerEvent: {} as any,
      showIntroSavedModalId: "",
      schedeoFormatDate,
      differenceInMinutes: differenceInMinutes,
      daysWithAvailability: new Array<{ date: Date; isAvailable: Boolean }>(),
      /**
       * @type {TimeRangeDTO[]}
       */
      availableDaySlots: new Array<TimeRange>(),
      selectedDay: {} as any,
      selectedTimeslot: {},

      event: {
        id: this.eventId,
        startDate: new Date(),
        endDate: new Date(),
        attendeeId: 0,
        attendeeName: "",
        attendeePhone: "",
        attendeeMail: "",
        attendeeNotes: "",
        acceptPolicies: false,
        locationId: -1
      },
      publicUser: createDefaultPublicOwner(),
      /**
       * @type {PublicAppointmentTypeDTO}
       */
      appType: createDefaultPublicAppointmentType()
    };
  },
  components: {
    /**
     * @type {object}
     */
    FormWizard,
    /**
     * @type {object}
     */
    TabContent,
    WizardButton,
    SchedeoCalendar,
    LogoComponent
  },

  props: {
    eventId: {
      type: String,
      default: ""
    },

    intro: {
      //Se questo è a true significa che devo gestire l'intro per i nuovi iscritti ( messaggi particolari, ecc)
      type: Boolean,
      default: false
    }
  },

  async mounted() {
    this.formerEvent = await getSingleAttendeeEvent(this.eventId);

    //Se è nel passato esco e in UI mostro che non posso rischedulare un evento già passato
    if (new Date(this.formerEvent.startDate) < new Date()) return;

    await this.loadDaysWithAvailability();
    this.transferFormerEventToEvent();

    this.publicUser = await getPublicProfileFromEventId(this.eventId);
    this.appType = await getPublicAppointmentTypeInfoFromEventId(this.eventId);

    if (this.appType.locationTypes.length === 1) {
      //Se ho solo una location seleziono quella.
      this.event.locationId = this.appType.locationTypes[0].id;
    }
  },
  computed: {
    enrichedLocationTypes(): any[] {
      return getEnrichedLocationTypes(this.appType);
    },
    isFormerEventInThePast(): Boolean {
      return new Date(this.formerEvent.startDate) < new Date();
    }
  },
  methods: {
    transferFormerEventToEvent() {
      this.event.startDate = this.formerEvent.startDate;
      this.event.endDate = this.formerEvent.endDate;
      this.event.attendeeId = this.formerEvent.attendeeId;
      this.event.attendeeName = this.formerEvent.attendeeName;
      this.event.attendeePhone = this.formerEvent.attendeePhone;
      this.event.attendeeMail = this.formerEvent.attendeeMail;
      this.event.attendeeNotes = this.formerEvent.attendeeNotes;
    },
    onDaySelected(day) {
      this.selectedDay = day;
      // this.$refs.wizard.nextTab();
      let wizard: any = this.$refs.wizard;
      wizard.nextTab();
    },
    async validateData() {
      let valObserver = this.$refs.bookValObserver as InstanceType<
        typeof ValidationObserver
      >;

      let isValid = await valObserver.validate();
      if (isValid) return true;
      else return "Controllare i campi";
    },
    async onStepChange(prevIndex, nextIndex) {
      //Mi assicuro di essere nella pagina giusta perchè al router.push viene raisato un altro onStepChange (non chiaro il motivo)
      //let currentRoute = this.$router.currentRoute.name??'';
      let currentRoute = this.$router.currentRoute.name? this.$router.currentRoute.name:'';
      let componentName = this.$options.name ? this.$options.name : '';
      //if (this.$router.currentRoute.name?.includes(this.$options.name??'')) {
        if (currentRoute && currentRoute.includes(componentName)) {
        switch (nextIndex) {
          case 1: //timeSlots
            this.loadTimeSlots();

            break;
        }
      }
    },
    isDate(checkDate) {
      return checkDate instanceof Date;
    },
    async loadDaysWithAvailability() {
      let schedeoCalendar = this.$refs.schedeoCalendar as any;
      this.daysWithAvailability = await this.getDaysWithAvailability(
        schedeoCalendar.currentYear,
        schedeoCalendar.currentMonth,
        this.eventId
      );
    },
    async getDaysWithAvailability(year, month, eventId) {
      let currentCalendarDays = getDaysInMonth(year, month);

      let sDate = new Date(year, month - 1);
      let eDate = addMonths(new Date(sDate), 1);

      let availabilities = await availability.GetAppointmentEventRescheduleAvailabilities(
        sDate,
        eDate,
        eventId
      );

      //let daysWithAvailability = [];
      let daysWithAvailability = new Array<{
        date: Date;
        isAvailable: Boolean;
      }>();

      currentCalendarDays.forEach(element => {
        let currentElementDate = new Date(
          element.year,
          element.month - 1,
          element.day
        );

        let dayAvailabilities = availabilities.filter(
          d =>
            d.start.getDate() == element.day &&
            d.start.getMonth() + 1 == element.month &&
            d.start.getFullYear() == element.year
        );

        daysWithAvailability.push({
          date: currentElementDate,
          isAvailable: dayAvailabilities.length > 0
        });
      });

      return daysWithAvailability;
    },
    async loadTimeSlots() {
      let day = this.selectedDay;
      let sDate = new Date(day.year, day.month - 1, day.day);
      let eDate = addDays(new Date(sDate), 1);

      this.availableDaySlots = await availability.GetAppointmentEventRescheduleAvailabilities(
        sDate,
        eDate,
        this.eventId
      );
    },
    async onTimeslotClick(timeslot) {
      this.selectedTimeslot = timeslot.start;
      this.event.startDate = timeslot.start;
      this.event.endDate = timeslot.end;

      //this.$refs.wizard.nextTab();
      let wizard: any = this.$refs.wizard;
      wizard.nextTab();
    },
    async onCalendarMonthMove(page) {
      await this.loadDaysWithAvailability();
    },

    async rescheduleSingleAttendeeEvent() {
      let wizard: any = this.$refs.wizard;
      try {
        
        await rescheduleSingleAttendeeEvent(this.event);
      } catch (ex:any) {
        if (ex.response.data.errorCode === "TimeSlotNotAvailable") {
          //show message and go back to start
          this.$showAlert(ex.response.data.safeMessage);
          await this.loadDaysWithAvailability();
          //this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
          wizard.changeTab(wizard.activeTabIndex, 0);
        } else {
          throw ex;
        }
      }
     // this.$refs.wizard.nextTab();
     wizard.nextTab();
    }
  }
});
//)
