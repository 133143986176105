<template>
  <div class="containerz">
    <h1>
      <span class="typed-text">{{ typeValue }}</span>
      <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
    </h1>
  </div>
</template>

<script>
import { setTimeout } from "timers";
export default {
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      // typeArray: ["fun", "awesome", "a journey", "life"],
      typingSpeed: 25,
      erasingSpeed: 20,
      newTextDelay: 2000,
      typeArrayIndex: 0,
      charIndex: 0
    };
  },
  props: {
    textToWrite: { type: String, default: "" }
  },
  methods: {
    async typeText() {
       
      while (this.charIndex < this.textToWrite.length) {
         //debugger;
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.textToWrite.charAt(this.charIndex);
        this.charIndex += 1;
        //setTimeout(this.typeText, this.typingSpeed);
        
        await this.sleep(this.typingSpeed);
      }
    },
    async eraseText() {
        this.typeValue = "";
        this.charIndex = 0;
    //   while (this.charIndex > 0) {
    //     if (!this.typeStatus) this.typeStatus = true;
    //     this.typeValue = this.textToWrite.substring(0, this.charIndex - 1);
    //     this.charIndex -= 1;
    //     await this.sleep(this.erasingSpeed);
    //   }
    },
    sleep(delay){
        return new Promise((resolve)=>setTimeout(resolve,delay));
    }

    //   typeText() {
    //     if(this.charIndex < this.typeArray[this.typeArrayIndex].length) {
    //       if(!this.typeStatus)
    //         this.typeStatus = true;
    //       this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
    //       this.charIndex += 1;
    //       setTimeout(this.typeText, this.typingSpeed);
    //     }
    //     else {
    //       this.typeStatus = false;
    //       setTimeout(this.eraseText, this.newTextDelay);
    //     }
    //   },
    //   eraseText() {
    //     if(this.charIndex > 0) {
    //       if(!this.typeStatus)
    //         this.typeStatus = true;
    //       this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
    //       this.charIndex -= 1;
    //       setTimeout(this.eraseText, this.erasingSpeed);
    //     }
    //     else {
    //       this.typeStatus = false;
    //       this.typeArrayIndex += 1;
    //       if(this.typeArrayIndex >= this.typeArray.length)
    //         this.typeArrayIndex = 0;
    //       setTimeout(this.typeText, this.typingSpeed + 1000);
    //     }
    //   }
    // },
    // created() {
    //   setTimeout(this.typeText, this.newTextDelay + 200);
  },
  async created() {
    this.typeText();
  },
  watch: {
    textToWrite() {
      this.eraseText();
      this.typeText();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  // height: 100vh;
  display: flex;
  //   justify-content: center;
  //align-items: center;
}
h1 {
  font-size: 1rem;
  font-weight: normal;
  //   span.typed-text {
  //     color: #d2b94b;
  //   }
  span.cursor {
    display: inline-block;
    margin-left: 3px;
    width: 4px;
    background-color: #fff;
    animation: cursorBlink 1s infinite;
  }
  span.cursor.typing {
    animation: none;
  }
}
@keyframes cursorBlink {
  49% {
    background-color: #fff;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}
</style>
