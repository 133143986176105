// Config object to be passed to Msal on creation
//import {LogLevel, PublicClientApplication} from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    //clientId: 'c4348499-37cb-4f48-8bb0-cf755af3738f',
    clientId: 'c2bda6f2-a3a3-418d-8a7f-dc4219bd85db',
    //redirectUri: 'http://localhost:8080/', // Must be registered as a SPA redirectURI on your app registration
    //postLogoutRedirectUri: 'http://localhost:8080/' // Must be registered as a SPA redirectURI on your app registration
  }
/*
,
mode: "popup",
cache: {
  cacheLocation: 'localStorage'
},
system: {
  loggerOptions: {
    loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
      if (containsPii) {
        return;
      }
      switch (level) {
        case LogLevel.Error:
          console.error(message);
          return;
        case LogLevel.Info:
          console.info(message);
          return;
        case LogLevel.Verbose:
          console.debug(message);
          return;
        case LogLevel.Warning:
          console.warn(message);
          return;
        default:
          return;
      }
    },
    logLevel: LogLevel.Verbose
  }
}
 */
};

export default msalConfig;
