






















import Vue, { VueConstructor } from "vue";

export default Vue.extend({
  name: "logo-manager",
  data() {
    return {
      logoFile: null as File | any
      //fullLogoUrl: "" as string aaaaa
    };
  },
  props: {
    fullLogoUrl: {
      type: String
    }
  },
  methods: {
    emitLogoChosen() {
      if (this.logoFile != null) this.$emit("logo-chosen", this.logoFile);
    },
    emitLogoRemoved(){
        this.$emit("logo-removed")
    }
  }
});
