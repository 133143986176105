import { TeamUser } from "@/data/Models/Team/TeamUser";
import { TeamInvitation } from "@/data/Models/Team/TeamInvitation";

export interface Team {
    id: string;
    name: string;
    url: string;
    logoUrl: string;
    teamUsers: Array<TeamUser>;
    teamInvitations: Array<TeamInvitation>;
  }

  export function createDefaultTeam(): Team {
    let defaultValue: Team = {
      id: "",
      name:"",
      url:"",
      logoUrl:"",
      teamUsers:[] as TeamUser[],
      teamInvitations:[] as TeamInvitation[]

    };
  
    return defaultValue;
  }