import apiClient from '../util/ApiClient';


// export const GetAppointmentTypeAvailableDays = async function (year, month, userUrl, appUrl) {

//     return apiClient.get('Appointment/GetAppointmentTypeAvailableDays', { params: { year, month, userUrl, appUrl } });
// }
export const GetAppointmentEventRescheduleAvailabilities = async function (sDate, eDate, eventId) {

    let baseResults = (await apiClient.get('Appointment/GetAppointmentEventRescheduleAvailabilities', { params: { sDate, eDate, eventId } })).data;
    let result = baseResults.map(s => ({
        start: new Date(s.start),
        end: new Date(s.end),
    }));

    return result;
}


export const GetAppointmentTypeAvailabilities = async function (sDate, eDate, ownerUrl, appUrl) {

    let baseResults = (await apiClient.get('Appointment/GetAppointmentTypeAvailabilities', { params: { sDate, eDate, ownerUrl, appUrl } })).data;
    let result = baseResults.map(s => ({
        start: new Date(s.start),
        end: new Date(s.end),
    }));

    return result;
}



export const GetMonthDays = async function (monthDate) {
    let stringDays = (await apiClient.get('Appointment/GetMonthDays', { params: { monthDate } })).data;
    let days = [];

    if (stringDays && stringDays.length > 0)
        days = stringDays.map(s => new Date(s));

    return days;
}
