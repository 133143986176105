
















































































import Vue, { VueConstructor } from "vue";
import { PublicAppointmentType } from "@/data/Models/Appointment/PublicAppointmentType";
import { getPublicAppointmentTypes, getPublicProfileFromOwnerUrl } from "@/data/appointmentService";
import { PublicOwner } from "@/data/Models/User/PublicOwner";
import LogoComponent from "@/components/LogoComponent.vue";
import globalMixins from "@/util/mixins/MyGlobalMixins.vue";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof globalMixins>
>).extend({
  name: "main-booking-page",
  mixins: [globalMixins],
  components: { LogoComponent },
  props: {
    ownerUrl: { type: String, default: "" }
  },
  data() {
    return {
      appTypes: [] as PublicAppointmentType[],
      publicOwner: {} as PublicOwner,
      welcomeMessage:
        "Benvenuto nella pagina di prenotazione.\r\n Clicca su un tipo di appuntamento per vedere le disponibilità e fissare un appuntamento."
    };
  },
  async mounted() {
    this.appTypes = await getPublicAppointmentTypes(this.ownerUrl);
    this.publicOwner = await getPublicProfileFromOwnerUrl(this.ownerUrl);

    this.setWelcomeMessage();
  },
  methods: {
    setWelcomeMessage(): void {
      let showCustomWelcomeMessage =
        this.isValidObject(this.publicOwner) &&
        !!this.publicOwner.mainBookingPageWelcomeMessage;

      if (showCustomWelcomeMessage)
        this.welcomeMessage = this.publicOwner.mainBookingPageWelcomeMessage;
    }
  }
});
