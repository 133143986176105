













































import Vue, { PropType } from "vue";
import {
  getReceiverTeamInvitation,
  acceptInvite,
  refuseInvite
} from "@/data/services/TeamServices/TeamMemberService";
import {
  ReceiverTeamInvitation,
  createDefaultReceiverTeamInvitation
} from "@/data/Models/Team/ReceiverTeamInvitation";

export default Vue.extend({
  name: "team-invitation-management",
  data() {
    return {
      receiverTeamInvitation: createDefaultReceiverTeamInvitation()
    };
  },
  props: {
    teamInvitationId: {
      type: String
    }
  },
  async mounted() {
    await this.loadInterface();
  },
  methods: {
    async loadInterface() {
        this.receiverTeamInvitation = await getReceiverTeamInvitation(
          this.teamInvitationId
        );
     
    },
    async acceptInvite() {
      await acceptInvite(this.teamInvitationId);
      await this.$showModalMessage("Ottimo", "Invito accettato.");
      this.$router.push({name:"teams"});
    },
     async refuseInvite() {
      await refuseInvite(this.teamInvitationId);
      await this.$showModalMessage("Ottimo", "Invito rifiutato.");
      this.$router.push({name:"teams"});
    }
  }
});
