

















































import Vue, { PropType } from "vue";
//components
import AppTypeGroupMainPageSection from "@/components/AppTypeGroup/AppTypeGroupMainPageSection.vue";
import AppTypeCard from "@/components/AppTypeGroup/AppTypeCard.vue";

import { TeamWithAppTypeSummary } from "@/data/Models/Appointment/TeamWithAppTypeSummary";
import { AppTypeSummary } from "@/data/Models/Appointment/AppTypeSummary";

export default Vue.extend({
  name: "app-type-group",
  components: { AppTypeGroupMainPageSection, AppTypeCard },
  props: {
    // teamWithAppTypeSummary: {
    //   type: Object as PropType<TeamWithAppTypeSummary>
    // },
    ownerName: {
      type: String
    },
    ownerUrl: {
      type: String
    },
    appTypeSummaries: {
      type: Array as PropType<Array<AppTypeSummary>>
    },
    appTypeDiscriminator: {
      type: String
    },
    teamId: {
      type: String,
      default: ""
    }
  },

  methods: {
    onAppTypeEnabledStatusChanged(eventArgs: {
      appTypeId: number;
      newEnableStatus: boolean;
    }) {
      this.$emit("app-type-enabled-status-changed", {
        appTypeId: eventArgs.appTypeId,
        newEnableStatus: eventArgs.newEnableStatus
      });
    },
    onAppTypeDeleteRequested(appTypeSummary: AppTypeSummary) {
      //rimando solamente verso l'alto
      this.$emit("app-type-delete-requested", appTypeSummary );
    }
  },
  computed: {
    sortedAppTypeSummaries(): AppTypeSummary[] {
      return this.appTypeSummaries.slice().sort((a, b) => {
        let result = (b.isEnabled ? 1 : 0) - (a.isEnabled ? 1 : 0);
        return result;
      });
    }
  }
});
