<template>
  <div>
    <b-card no-body class="page-card">
      <b-card-header>
        <div class="text-right">
            <b-button variant="link" @click="showHelp">Istruzioni</b-button>
        </div>
        <div>
          <b-dropdown text="Aggiungi Calendario" variant="primary">
            <b-dropdown-item @click="addGoogleCalendarAccount">Calendario Google</b-dropdown-item>
            <b-dropdown-item @click="addOutlookCalendarAccount">Calendario Outlook / Office365</b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- <b-row v-if="false">
          <b-col class="mb-2 d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <b-button variant="primary" @click="addGoogleCalendarAccount"
                >Aggiungi Calendario Google</b-button
              >
              <b-button variant="primary" @click="addOutlookCalendarAccount"
                >Aggiungi Calendario Outlook / Office365</b-button
              >
            </div>
            <div></div>
          </b-col>
        </b-row> -->
      </b-card-header>
      <b-card-body>
        <div>
          <calendar-account
            v-for="c in calendarAccounts"
            :key="c.id"
            :calendarAccount="c"
            :fields="fields"
            @deleted-calendar-account="loadAccountsAndCalendars"
          >
          </calendar-account>
        </div>
      </b-card-body>
      <b-card-footer>
        <div class="text-right">
          <b-button @click="saveCalendarAccounts" variant="primary">
            Salva
          </b-button>
        </div>
      </b-card-footer>
    </b-card>
    <!-- <intro-message-box ref="introMessageBox" v-if="intro===true" /> -->
  </div>
</template>
<script>
import CalendarAccount from "@/components/CalendarAccount.vue";
import * as calendarAccount from "@/data/calendarAccount";
// import IntroMessageBox from "@/components/calendars/IntroMessageBox.vue";
import "@/data/Models/CalendarAccountDTO";
import "@/data/Models/CalendarDTO";
import { handleError } from "@/util/errorHandler";
import msalConfig from "@/plugins/ms-graph/msalConfig";

export default {
  name: "Calendars",
  data() {
    return {
      fields: ["calendarName", "trackConflict", "addToCalendar"],

      // 1 -> no calendars
      // 2 -> not saved
      // 3 -> ready to try appointment
      introCurrentCheckpoint: 1
    };
  },
  props: {
    intro: {
      //Se questo è a true significa che devo gestire l'intro per i nuovi iscritti ( messaggi particolari, ecc)
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: ""
    },
    state: {
      type: String,
      default: ""
    }
  },
  async mounted() {
    if (this.code != "") {
      await this.addOutlookCalendarAccountFinalize();
    }
    await this.loadAccountsAndCalendars();

    if (this.intro === true) {
      //Se ho almeno un calendar account già aggiunto, imposto il checkpint a 2
      if (this.isAtLeastOneCalendarCorrectlyConfigured(this.calendarAccounts))
        this.introCurrentCheckpoint = 2;
    }
  },
  components: { CalendarAccount },
  async beforeRouteLeave(to, from, next) {
    await this.introGuardAgainstRouteChanges(to, from, next);
  },
  async beforeRouteUpdate(to, from, next) {
    await this.introGuardAgainstRouteChanges(to, from, next);
  },
  methods: {
    async introGuardAgainstRouteChanges(to, from, next) {
      //Siccome il metodo è async, per far catchare l'eccezione devo wrappare in una try catch e chiamare next(errore) per farla funzionare correttamente.
      //https://stackoverflow.com/questions/62045128/why-do-errors-in-vue-router-beforeeach-throw-no-exception-when-running-in-v8js
      try {
        //registro l'evento, e necessario, prima di procedere, controllo.
        if (this.intro === false) {
          next();
        } else {
          //Se sono in intro, allora controllo se ho associato e configurato correttamente dei calendari

          let safeDestinations = ["/login"];
          let goingToSafeDestination =
            safeDestinations.includes(to.fullPath) ||
            safeDestinations.includes(to.query["redirect"]);

          if (goingToSafeDestination) {
            next();
          }

          switch (this.introCurrentCheckpoint) {
            case 1: //starting point -> need to add calendar
              await this.$showModalMessage(
                "Attenzione",
                "Devi completare la configurazione di base.\r\n Aggiungi un calendario."
              );
              break;
            case 2: //calendar added -> need to save
              await this.$showModalMessage(
                "Attenzione",
                "Verifica di aver impostato 'Traccia Conflitti' e 'Aggiungi Eventi' su un calendario.\r\n\r\nQuando hai controllato premi 'Salva'"
              );
              break;

            default:
              next();
              break;
          }
        }
      } catch (ex) {
        next(ex);
      }
    },
    /**
     *
     * @param {Array<CalendarAccountDTO>} accountsAndCalendars
     * @returns {Boolean}
     */
    isAtLeastOneCalendarCorrectlyConfigured(accountsAndCalendars) {
      //Verifico che almeno un calendario abbia sia addTo che Track.
      if (!accountsAndCalendars) return false;

      let correctlyConfiguredCalendars = accountsAndCalendars.filter(ca =>
        ca.calendars.some(
          c => c.trackConflicts === true && c.addToCalendar === true
        )
      );
      return correctlyConfiguredCalendars.length > 0;
    },

    async saveCalendarAccounts() {
      let result = await calendarAccount.saveCalendarAccounts(
        this.calendarAccounts
      );

      await this.loadAccountsAndCalendars();
      await this.$showAlertBarSaveSuccessfull();

      if (this.intro === true) {
        //Verifico se ho aggiunto almeno un calendario
        if (this.calendarAccounts.length === 0) {
          await this.$showModalMessage(
            "Attenzione",
            "Devi completare la configurazione di base.\r\n Aggiungi un calendario."
          );
          return;
        }

        let isCorrectlyConfigured = this.isAtLeastOneCalendarCorrectlyConfigured(
          this.calendarAccounts
        );
        if (isCorrectlyConfigured === false) {
          //Se non ho almeno un calendario con track e addTo, allora informo l'utente
          await this.$showModalMessage(
            "Attenzione",
            "Devi impostare 'Traccia Conflitti' e 'Aggiungi Eventi' su un calendario."
          );
          return;
        }
        this.introCurrentCheckpoint = 3;
        //this.$refs.introMessageBox.show();
        await this.$showModalMessage(
          "Ottimo",
          "Configurazione di base completata"
        );
        this.$router.push({
          name: "how_does_it_work"
        });
      }
    },

    async addGoogleCalendarAccount() {
      await this.$showTimedModalMessage(
        "Attenzione",
        "Nelle prossime schermate metti la spunta a tutti i permessi, altrimenti Schedeo non funzionerà.",
        0
      );

      let authCode;

      try {

        authCode = await this.$gAuth.getAuthCode();

        let result = await calendarAccount.addGoogleCalendarAccount(
          authCode,
          window.location.origin
        );
        await this.loadAccountsAndCalendars();
        if (this.intro === true) {
          this.introCurrentCheckpoint = 2;
          let msg =
            "Ora assicurati che 'Traccia Conflitti' e 'Aggiungi Eventi' siano spuntati sul calendario che vuoi usare con schedeo.\r\n\r\n";
          msg += "Quando hai finito di controllare premi Salva.";
          await this.$showTimedModalMessage("Perfetto", msg, 0);
        }
      } catch (e) {
        switch (e){
          case "popup_failed_to_open":
            this.$showAlert("Potresti avere i popup bloccati, sbloccali per procedere.");
            break;
          case "popup_closed":
            //this.$showAlert("Popup chiuso");
            break;
          // case "unknown":
          //   throw e
          //   break;
          default:
            throw e;
            break;
        }
        //if (e.error !== "popup_closed_by_user") throw e;
      }
    },
    async addOutlookCalendarAccount() {
      // await this.$showTimedModalMessage(
      //   "Attenzione",
      //   "Nelle prossime schermate metti la spunta a tutti i permessi, altrimenti Schedeo non funzionerà.",
      //   0
      // );

      const calendarRedirectUrl = encodeURIComponent(
        `${window.location.origin}/calendars/`
      );
      const redirectUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${msalConfig.auth.clientId}&redirect_uri=${calendarRedirectUrl}&response_type=code&response_mode=query&scope=offline_access%20user.read%20calendars.readwrite&state=o365connect&prompt=select_account`;

      window.location.href = redirectUrl;
    },
    async addOutlookCalendarAccountFinalize() {
      try {
        let result = await calendarAccount.addO365CalendarAccount(
          this.code,
          window.location.origin + "/calendars/"
        );
        //valecass non va awaitato?
        await this.loadAccountsAndCalendars();
        if (this.intro === true) {
          this.introCurrentCheckpoint = 2;
          let msg =
            "Ora assicurati che 'Traccia Conflitti' e 'Aggiungi Eventi' siano spuntati sul calendario che vuoi usare con schedeo.\r\n\r\n";
          msg += "Quando hai finito di controllare premi Salva.";
          await this.$showTimedModalMessage("Perfetto", msg, 0);
        }
      } catch (exception) {
        throw exception;
      } finally {
        await this.$router.push("/calendars");
      }
    },
    async loadAccountsAndCalendars() {
      await this.$store.dispatch("getAccountsAndCalendars");
    },
    async showHelp() {
      await this.$showHtmlModalMessage(
        "Calendari",
        `Qui colleghi i calendari che vuoi usare con schedeo.<br>
        Per ogni calendario puoi impostare due opzioni:<br><br>
        <ul>
        <li><b>Traccia Conflitti:</b> Selezionandolo le disponibilità della pagina prenotazione terranno conto degli appuntamenti che hai già nel calendario.<br><br></li>
        <li><b>Aggiungi Eventi:</b> Selezionandolo gli appuntamenti presi con schedeo verranno aggiunti automaticamente al tuo calendario.</li>
        </ul>`
      );
    }
  },
  computed: {
    /**
     * @returns {Array<CalendarAccountDTO>}
     */
    calendarAccounts() {
      return this.$store.state.AccountsAndCalendars;
    }
  },
  watch: {
    calendarAccounts: function() {
      //Se sono in intro e per qualche ragione l'utente decide di scollegare un account verifico e se necessario riporto il checkpoint a 1
      //in modo che debba ri-aggiungerlo.
      if (this.intro === true) {
        if (this.calendarAccounts.length === 0) this.introCurrentCheckpoint = 1;
      }
    }
  }
};
</script>
<style scoped>
.arrow {
  font-size: 40px;
  color: #fb6340;
}
</style>
