var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"billingDetailsValidationObserver"},[_c('div',{staticClass:"pt-3"},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',{staticClass:"form-control-label"},[_vm._v("La fattura deve essere intestata a:")]),_c('b-form-radio',{attrs:{"value":"Company","name":"customerType"},model:{value:(_vm.billingDetail.customerType),callback:function ($$v) {_vm.$set(_vm.billingDetail, "customerType", $$v)},expression:"billingDetail.customerType"}},[_vm._v("Una società")]),_c('b-form-radio',{attrs:{"value":"Private","name":"customerType"},model:{value:(_vm.billingDetail.customerType),callback:function ($$v) {_vm.$set(_vm.billingDetail, "customerType", $$v)},expression:"billingDetail.customerType"}},[_vm._v("Un privato")])],1)],1)],1),_c('b-row',[(_vm.billingDetail.customerType == 'Private')?[_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Nome","rules":{
              required: true
            },"type":"text","label":"Nome","placeholder":"Nome","mode":_vm.mode},model:{value:(_vm.billingDetail.firstName),callback:function ($$v) {_vm.$set(_vm.billingDetail, "firstName", $$v)},expression:"billingDetail.firstName"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Cognome","rules":{
              required: true
            },"type":"text","label":"Cognome","placeholder":"Cognome","mode":_vm.mode},model:{value:(_vm.billingDetail.lastName),callback:function ($$v) {_vm.$set(_vm.billingDetail, "lastName", $$v)},expression:"billingDetail.lastName"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"CF","rules":{
              required: true
            },"type":"text","label":"Codice Fiscale","placeholder":"Codice Fiscale","mode":_vm.mode},model:{value:(_vm.billingDetail.fiscalCode),callback:function ($$v) {_vm.$set(_vm.billingDetail, "fiscalCode", $$v)},expression:"billingDetail.fiscalCode"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Indirizzo","rules":{
              required: true
            },"type":"text","label":"Indirizzo","placeholder":"Via e Civico","mode":_vm.mode},model:{value:(_vm.billingDetail.address),callback:function ($$v) {_vm.$set(_vm.billingDetail, "address", $$v)},expression:"billingDetail.address"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Città","rules":{
              required: true
            },"type":"text","label":"Città","placeholder":"Città","mode":_vm.mode},model:{value:(_vm.billingDetail.city),callback:function ($$v) {_vm.$set(_vm.billingDetail, "city", $$v)},expression:"billingDetail.city"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Cap","rules":{
              required: true
            },"type":"text","label":"Cap","placeholder":"Cap","mode":_vm.mode},model:{value:(_vm.billingDetail.zipCode),callback:function ($$v) {_vm.$set(_vm.billingDetail, "zipCode", $$v)},expression:"billingDetail.zipCode"}})],1)]:_vm._e(),(_vm.billingDetail.customerType === 'Company')?[_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Ragione Sociale","rules":{
              required: true
            },"type":"text","label":"Ragione Sociale","placeholder":"Ragione Sociale","mode":_vm.mode},model:{value:(_vm.billingDetail.companyName),callback:function ($$v) {_vm.$set(_vm.billingDetail, "companyName", $$v)},expression:"billingDetail.companyName"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Partita Iva","rules":{
              required: true
            },"type":"text","label":"Partita Iva","placeholder":"Partita Iva","mode":_vm.mode},model:{value:(_vm.billingDetail.vat),callback:function ($$v) {_vm.$set(_vm.billingDetail, "vat", $$v)},expression:"billingDetail.vat"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Indirizzo","rules":{
              required: true
            },"type":"text","label":"Indirizzo","placeholder":"Via e Civico","mode":_vm.mode},model:{value:(_vm.billingDetail.address),callback:function ($$v) {_vm.$set(_vm.billingDetail, "address", $$v)},expression:"billingDetail.address"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Città","rules":{
              required: true
            },"type":"text","label":"Città","placeholder":"Città","mode":_vm.mode},model:{value:(_vm.billingDetail.city),callback:function ($$v) {_vm.$set(_vm.billingDetail, "city", $$v)},expression:"billingDetail.city"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Cap","rules":{
              required: true
            },"type":"text","label":"Cap","placeholder":"Cap","mode":_vm.mode},model:{value:(_vm.billingDetail.zipCode),callback:function ($$v) {_vm.$set(_vm.billingDetail, "zipCode", $$v)},expression:"billingDetail.zipCode"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Pec","type":"text","label":"Pec ( opzionale )","placeholder":"Pec","mode":_vm.mode},model:{value:(_vm.billingDetail.pec),callback:function ($$v) {_vm.$set(_vm.billingDetail, "pec", $$v)},expression:"billingDetail.pec"}})],1),_c('b-col',{attrs:{"lg":_vm.columnLg}},[_c('base-input',{attrs:{"name":"Codice Destinatario","type":"text","label":"Codice Destinatario ( opzionale )","placeholder":"Codice Destinatario","mode":_vm.mode},model:{value:(_vm.billingDetail.codiceDestinatario),callback:function ($$v) {_vm.$set(_vm.billingDetail, "codiceDestinatario", $$v)},expression:"billingDetail.codiceDestinatario"}})],1)]:_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }