import { Team } from "@/data/Models/Team/Team";
import apiClient from "@/util/ApiClient";

export const getTeams = async function(): Promise<Team[]> {
  let result = await apiClient.get("Teams/GetTeams");
  return result.data;
};

export const getTeamById = async function(teamId: string): Promise<Team> {
  let result = await apiClient.get("Teams/GetTeamById", {
    params: { teamId }
  });
  return result.data;
};

export const setLogo = async function(file: File, teamId: string) {
  const formData = new FormData();
  formData.append("File", file);

  const response = await apiClient.post(`Teams/SetLogo`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    params: {
      teamId
    }
  });
  //return response.data;
};

export const removeLogo = async function(teamId: string) {
  return apiClient.delete("Teams/RemoveLogo", {
    params: { teamId }
  });
};

export const createTeam = async function(
  teamName: string,
  teamUrl: string
): Promise<string> {
  let result = await apiClient.post("Teams/CreateTeam", null, {
    params: { teamName, teamUrl }
  });
  //restituisco l'id
  return result.data;
};

export const saveTeam = async function(
  teamId: string,
  teamName: string,
  teamUrl: string
) {
  let result = await apiClient.post("Teams/SaveTeam", null, {
    params: { teamId, teamName, teamUrl }
  });
  return result;
};

export const deleteTeam = async function(
  teamId: string
) {
  let result = await apiClient.delete("Teams/DeleteTeam", {
    params: { teamId}
  });
  return result;
};
