


































































































































































import { copyToClipboard } from "@/util/helpers";
import router from "../routes/router";
import store from "@/data/store.js";
import Vue, { PropType } from "vue";

//services
import {
  getMainBookingPageUrl,
  getPersonalAppointmentTypes,
  setAppointmentTypeIsEnabled,
  deleteAppointmentType
} from "@/data/appointmentService";

import {
  getTeamAppointmentTypes,
  canCreateTeamAppType
} from "@/data/services/AppTypeServices/TeamAppTypeService";

import { getTeams } from "@/data/services/TeamServices/TeamService";

//typedef
import "@/data/Models/AppointmentTypeDTO";
import { AppointmentType } from "@/data/Models/Appointment/AppointmentType";
import { TeamWithAppTypeSummary } from "@/data/Models/Appointment/TeamWithAppTypeSummary";
import { AppTypeSummary } from "@/data/Models/Appointment/AppTypeSummary";
import { AppTypeDiscriminiator } from "@/data/Models/Appointment/BaseAppType";

//components
import AppTypeGroup from "@/components/AppTypeGroup/AppTypeGroup.vue";
import { Team } from "@/data/Models/Team/Team";

function initAddAppTypeModalData() {
  return { currentStep: 0, teams: [] as Team[], selectedNewAppType:AppTypeDiscriminiator.Blank };
}

export default Vue.extend({
  name: "AppointmentTypes",
  components: { AppTypeGroup },
  data() {
    return {
      teamWithAppTypeSummaries: [] as TeamWithAppTypeSummary[],
      personalAppTypeSummaries: [] as AppTypeSummary[],
      activeCollapses: [] as string[],
      addAppTypeModalData: initAddAppTypeModalData(),
      //addAppTypeModalData: { currentStep: 0 }
    };
  },

  async mounted() {
    await this.loadAppointmentTypes();
  },
  // async beforeRouteUpdate(to, from, next) {
  //   debugger;
  //   console.log("before");
  //   await this.loadAppointmentTypes();
  //   next();
  // },
  methods: {
    async onAppTypeEnabledStatusChanged(eventArgs: {
      appTypeId: number;
      newEnableStatus: boolean;
    }) {
      try {
        await setAppointmentTypeIsEnabled(
          eventArgs.appTypeId,
          eventArgs.newEnableStatus
        );

        await this.$showAlertBarSaveSuccessfull();
      } finally {
        await this.loadAppointmentTypes();
      }
    },
    async onAppTypeDeleteRequested(appTypeSummary: AppTypeSummary) {
      try {
        await deleteAppointmentType(
          appTypeSummary.id,
          appTypeSummary.discriminator
        );
        await this.$showAlertBarSaveSuccessfull();
      } finally {
        await this.loadAppointmentTypes();
      }
    },
    async loadAppointmentTypes() {
      this.teamWithAppTypeSummaries = await getTeamAppointmentTypes();
      this.personalAppTypeSummaries = await getPersonalAppointmentTypes();
    },
    onNewPersonalAppTypeClicked() {
      this.goToCreatePersonalAppType();
    },
    async onNewTeamAppTypeClicked() {
      this.addAppTypeModalData.selectedNewAppType = AppTypeDiscriminiator.TeamAppointmentType;
      await this.showSelectTeamStep();
    },
    async onNewRoundRobinAppTypeClicked() {
      this.addAppTypeModalData.selectedNewAppType = AppTypeDiscriminiator.RoundRobinAppointmentType;
      await this.showSelectTeamStep();
    },
    async showSelectTeamStep() {
      this.addAppTypeModalData.currentStep = 1;
      let modal = this.$refs["add-apptype-modal"] as any;

      //Verifico se l'utente ha il plan
      let canCreate = await canCreateTeamAppType();
      
      if (!canCreate) {
        //console.log("non puoi");
        modal.hide();
        await this.$showModalMessage(
          "Non incluso nell'abbonamento",
          "Questa funzionalità non è inclusa nel tuo attuale abbonamento.\r\nPer procedere passa a un abbonamento superiore."
        );
        return;
      }
      //Se si carico lista dei team
      this.addAppTypeModalData.teams = await getTeams();
      if (this.addAppTypeModalData.teams.length == 0) {
        modal.hide();
        await this.$showModalMessage(
          "Nessun Team trovato",
          //"Non appartieni a nessun team.\r\nPrima di creare un appuntamento di team crea un Team nella sezione apposita."
          "Questa pagina di prenotazione richiede un Team ma al momento non appartieni a nessun team.\r\nPrima di procedere creane uno nella sezione Teams."
        );
        return;
      }

      //Se no visualizzo mex che informa che deve creare un team prima.

      //console.log("hidden");
      // this.$router.push({
      //   name: "appointment_type_details",
      //   params: {
      //     id: "0",
      //     appTypeDiscriminator: AppTypeDiscriminiator.PersonalAppointmentType,
      //   }
      // });
    },
    onAddAppTypeModalClose() {
      this.addAppTypeModalData = initAddAppTypeModalData();
    },
    goToCreatePersonalAppType() {
      this.$router.push({
        name: "appointment_type_details",
        params: {
          id: "0",
          appTypeDiscriminator: AppTypeDiscriminiator.PersonalAppointmentType
        }
      });
    },
    goToCreateTeamAppType(teamId: string) {
      console.log(this.addAppTypeModalData.selectedNewAppType);
      this.$router.push({
        name: "appointment_type_details",
        params: {
          id: "0",
          appTypeDiscriminator: this.addAppTypeModalData.selectedNewAppType,
          teamId: teamId
        }
      });
    },
   
    async showHelpTeamAppType(event) {
      event.stopPropagation();
      await this.$showHtmlModalMessage(
        "Team",
        `
        <strong>Un esempio di utilizzo</strong><br />
        Immagina di dover fare una riunione con un nuovo cliente. A questa riunione dovete prenotare sia tu che un tuo collega.<br/> <br/> 
        Se crei una pagina di prenotazione di Team, e aggiungi come organizzatori "sia te che i tuo collega" 
        quando qualcuno accederà alla pagina potrà prendere appuntamento solo quando sia tu che il tuo collega siete disponibili!<br /><br />
        `
      );
    },
    async showHelpRoundRobinAppType(event) {
      event.stopPropagation();
      await this.$showHtmlModalMessage(
        "Round Robin",
        `
        <strong>Un esempio di utilizzo</strong><br />
        Immagina un CAF con 3 operatori che possono gestire la Dichiarazione dei Redditi.<br/> Creando una pagina di prenotazione "Dichiarazione dei redditi" 
        quando qualcuno accederà alla pagina potrà prendere appuntamento quando almeno uno degli operatori è disponibile.<br /><br />
        `
      );
    }
  },

  computed: {
    /**
     * @returns {AppointmentTypeDTO}
     */
    // appointmentTypes(): AppointmentType[] {
    //   return this.$store.state.appointmentTypes;
    // },
    userUrl() {
      return store.state.currentUser.userUrl || "";
    },
    mainBookingPageUrl(): string {
      return getMainBookingPageUrl(router, this.userUrl, window);
      //return "";
    }
    /**
     * @returns {AppointmentTypeDTO}
     */
    // sortedAppointmentTypes(): AppointmentType[] {
    //   return this.appointmentTypes.slice().sort((a, b) => {
    //     let result = (b.isEnabled ? 1 : 0) - (a.isEnabled ? 1 : 0);
    //     console.log(result);
    //     return result;
    //   });
    // }
  }
});
