


























import { startOfDay } from "date-fns";
import Vue, { VueConstructor } from "vue";
/**
 * @typedef {Object} CalendarDay
 * @property {Date} date
 * @property {Boolean} isAvailable
 */
//export default 
export default Vue.extend(
{
  name: "schedeo-calendar",
  data() {
    return {};
  },
  props: {
    /**
     * @type {import('vue').PropType<Array<CalendarDay>>}
     */
    daysWithAvailability: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    async onDayClick(day) {
      if (day.attributes[0].highlight.base.class === "date-not-available") {
        if (day.date < startOfDay(new Date())) {
          this.$showAlert(
            "Non è possibile prenotare un appuntamento nel passato."
          );
        } else {
          this.$showAlert(
            "Non ci sono disponibilità nel giorno selezionato.\r\nSceglierne un altro."
          );
        }
        return;
      }
      this.$emit("daySelected", day);

      //   this.selectedDay = day;

      //   this.$refs.wizard.nextTab();
    },
    raiseTransitionEnd() {
      this.$emit("transition-end");
    }
  },
  computed: {
    /**
     * @returns {number}
     */
    currentMonth():number {
      // return this.$refs.calendar.pages[0].month;
      let calendar = this.$refs.calendar as any;
      return calendar.pages[0].month;
            
    },
    /**
     * @returns {number}
     */
    currentYear():number {
      //return this.$refs.calendar.pages[0].year;
      let calendar = this.$refs.calendar as any;
      return calendar.pages[0].year;
      
    },
    /**
     * @returns {Array<Object>}
     */
    calendarAttributes():Array<Object> {
      return (this.daysWithAvailability as any).map(day => {
        let dayAttribute = {} as any;
        dayAttribute.highlight = day.isAvailable
          ? { class: "date-available" }
          : { class: "date-not-available" };
        dayAttribute.dates = day.date;

        return dayAttribute;
      });
    }
  }
});
