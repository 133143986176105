var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"at-card mb-3",attrs:{"footer-class":"at-card-footer","header-class":"at-card-header","body-class":"p-3"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"link-no-underline pr-2 at-card-footer-button",staticStyle:{"color":"white"},on:{"click":function($event){return _vm.deleteAppointmentType(_vm.appTypeSummaryLocal)}}},[_vm._v(" Elimina ")]),_c('router-link',{staticClass:"link-no-underline pl-2 at-card-footer-button",staticStyle:{"color":"white"},attrs:{"to":{
          name: 'appointment_type_details',
          params: {
            id: _vm.appTypeSummaryLocal.id,
            appTypeDiscriminator: _vm.appTypeSummaryLocal.discriminator
          }
        }}},[_vm._v(" Modifica ")])],1)]},proxy:true}])},[_c('div',{staticClass:"at-card-header"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}},{name:"b-tooltip",rawName:"v-b-tooltip.d400",modifiers:{"d400":true}}],staticStyle:{"font-weight":"bold"},attrs:{"title":_vm.appTypeSummaryLocal.name}},[_vm._v(" "+_vm._s(_vm.appTypeSummary.name)+" ")]),_c('div',{staticClass:"text-center",staticStyle:{"flex":"0 0 90px"}},[_c('base-switch',{attrs:{"name":"Nome"},on:{"input":_vm.emitAppTypeEnabledStatusChanged},model:{value:(_vm.appTypeSummaryLocal.isEnabled),callback:function ($$v) {_vm.$set(_vm.appTypeSummaryLocal, "isEnabled", $$v)},expression:"appTypeSummaryLocal.isEnabled"}}),_c('div',{staticClass:"form-control-label ",class:[
            _vm.appTypeSummaryLocal.isEnabled ? 'color-green' : 'color-red'
          ],staticStyle:{"font-size":"0.7rem"}},[_vm._v(" "+_vm._s(_vm.appTypeSummaryLocal.isEnabled ? "Prenotabile" : "Non Prenotabile")+" ")])],1)])]),_c('div',{staticClass:" at-card-body",staticStyle:{"height":"127px"}},[_c('div',{staticClass:"p-2"},[_c('div',[_c('strong',{staticClass:"pr-1"},[_vm._v("Durata:")]),_vm._v(" "+_vm._s(_vm.appTypeSummaryLocal.durationMinutes)+" Min ")]),_c('div',[_c('strong',{staticClass:"pr-1"},[_vm._v("Tipo:")]),_vm._v(" "+_vm._s(_vm.discriminatorToItalianType(_vm.appTypeSummaryLocal.discriminator))+" ")])]),(_vm.appTypeSummaryLocal.isEnabled)?_c('div',{staticClass:"d-flex py-2 justify-content-center"},[_c('div',{staticClass:"text-center"},[_c('b-dropdown',{staticClass:"m-2",attrs:{"variant":"success","id":"dropdown-buttons","text":"Inserisci Appuntamento","toggle-class":"white-space-normal"}},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.copyBookingUrlToClipboard(_vm.appTypeSummaryLocal)}}},[_c('div',{staticClass:"white-space-normal"},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("Copia link Prenotazione")])]),_c('div',{staticClass:"subtitle"},[_vm._v(" Una volta copiato lo dovrai \"incollare\" ed inviare alla persona che deve prendere appuntamento (via Whatsapp, Mail, ecc ) ")])])]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{
              name: 'book',
              params: {
                ownerUrl: _vm.ownerUrl,
                appUrl: _vm.appTypeSummaryLocal.appUrl
              }
            },"target":"_blank"}},[_c('div',{staticClass:"white-space-normal"},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("Inserisci Appuntamento")])]),_c('div',{staticClass:"subtitle"},[_vm._v(" Inserisci un appuntamento per conto di un tuo cliente ")])])])],1)],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }