<template>
  <div>
    <b-card no-body class="page-card">
        
        <b-card-header>
          <b-row>
            <b-col sm="12" class="mb-3 d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <b-button variant="primary" @click="newAvailability"
                  >Crea Nuovo Set di Disponibilità</b-button
                >
              </div>
              <div>
                <b-button variant="link" @click="showHelp">Istruzioni</b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-col sm="12">
            <el-table
              v-if="availabilitySets.length > 0"
              class="table-responsive table-flush"
              :data="availabilitySets"
              :show-header="false"
            >
              <el-table-column prop="name">
                 </el-table-column>

              <el-table-column align="right" width="100">
                <template v-slot="{ row }">
                  <!-- <el-button size="mini">
                      <router-link
                        :to="{ name: 'availability', params: { id: row.id } }"
                        >Modifica</router-link
                      >
                     </el-button
                    > -->

                  <router-link
                    :to="{ name: 'availability', params: { id: row.id } }"
                    ><el-button size="mini">Modifica</el-button></router-link
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- <div v-if="availabilitySets.length==0" class="text-center">
                Non hai nessuna disponibilità, creane una!
              </div> -->

            <div
              v-if="
                this.availabilitySets.length === 0 &&
                  this.$store.getters.isLoading === false
              "
              class="pt-5"
            >
              <div class="text-center font-weight-bold">
                Non hai nessuna disponibilità, creane una!
              </div>
              <div style="max-width:500px;" class="ml-auto mr-auto">
                <b-img src="/img/images/undraw_empty_street_sfxm.png" fluid />
              </div>
            </div>
          </b-col>
        </b-card-body>
    </b-card>
  </div>
</template>
<script>
import * as availabilitySet from "@/data/availabilitySet";
// import AvailabilitySetOverrideTable from "@/components/AvailabilitySetOverrideTable.vue";

export default {
  name: "Availabilities",
  // components: { AvailabilitySetOverrideTable },
  data() {
    return {
      availabilitySets: []
      // selectedId: 0
    };
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },

  mounted() {
    this.loadAvailabilitySets();
  },
  methods: {
    async newAvailability() {
      if (this.$route.path == "/availabilities/0") {
        //Quickfix da sistemare, per il momento:
        //Se sono già in new prima torno ad availabilities per evitare l'errore di redundant di vue
        await this.$router.push("/availabilities");
      }

      await this.$router.push("/availabilities/0");
    },

    // updateAndSelect(id) {
    //   this.loadAvailabilitySets();

    //   if (id) this.selectAvailabilitySet(id);
    // },

    async loadAvailabilitySets() {
      let result = await availabilitySet.getAvailabilitySets();
      this.availabilitySets = result.data;
      // this.availabilitySets = [];
    },
    // isSelected(id) {
    //   return id === this.selectedId;
    // },
    // selectAvailabilitySet(id) {
    //   this.selectedId = id;
    //   this.$router.push({
    //     name: "availability",
    //     params: { id: id }
    //   });
    // },
    async showHelp() {
      await this.$showHtmlModalMessage(
        "Info",
        `Qui puoi definire quando sei disponibile.<br />
        Le disponibilità sono gli orari in cui vuoi essere "prenotabile" e che poi associerai a tuoi tipi di appuntamento.<br /><br />
        <strong>Importante</strong><br/>
        Quando hai creato o modificato una disponibilità salvala e poi associala a un tipo di appuntamento (devi andare nella sezione tipi di appuntamento).`
      );
    }
  }
};
</script>
<style scoped>
.availabilityItem {
  max-width: 300px;
  word-wrap: break-word;
}
.selected {
  color: #303030;
  background-color: #d0d7ff;
  border-color: #d0d7ff;
}
</style>
