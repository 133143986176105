<template>
  <div>
    <b-card no-body>
      <div>
        <b-card-header>
          <b-row>
            <b-col class="mb-2">
              <b-button variant="primary">Invita un altro membro</b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <el-table :data="users" style="width: 100%">
            <el-table-column prop="id" label="ID" width="180">
            </el-table-column>
            <el-table-column prop="email" label="Mail" width="180">
            </el-table-column>
            <el-table-column
              prop="currentPlan"
              label="Current Plan"
              width="180"
            >
            </el-table-column>
            <el-table-column label="Operations">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  >Modifica</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >Rimuovi</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>
<script>
// import { Table,TableColumn } from 'element-ui';

export default {
  // components: {
  //   [Table.name]: Table,
  //   [TableColumn.name]: TableColumn,
  // //   [Dropdown.name]: Dropdown,
  // //   [DropdownItem.name]: DropdownItem,
  // //   [DropdownMenu.name]: DropdownMenu,
  // },
  data() {
    return {
      users: [
        {
          id: 11,
          email: "test1@test.it",
          currentPlan: "Plan Basic",
        },
        {
          id: 12,
          email: "test1@test.it",
          currentPlan: "Plan Basic",
        },
        {
          id: 13,
          email: "test1@test.it",
          currentPlan: "Plan Basic",
        },
      ],
    };
  },
  methods: {
    handleEdit(index, row) {
    },
  },
};
</script>
