import { AppointmentLocationType } from "@/data/Models/Appointment/AppointmentLocationType";

export interface PublicAppointmentType {
  name: string;
  durationMinutes: number;
  appUrl: string;
  locationTypes: AppointmentLocationType[];
}

export function createDefaultPublicAppointmentType(): PublicAppointmentType {
  let defaultObj: PublicAppointmentType = {
    name: "",
    durationMinutes: 0,
    appUrl: "",
    locationTypes: new Array<AppointmentLocationType>()
  };

  return defaultObj;
}
