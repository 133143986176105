




























































































































































































































import Vue, { VueConstructor } from "vue";
import * as account from "@/data/account";
import CreditCardManager from "@/components/UserSettings/CreditCardManager.vue";
import BillingDetails from "@/components/SubscriptionPayment/BillingDetails.vue";
import { cloneDeep } from "lodash";
import { ValidationProvider } from "vee-validate";
import { IBillingDetail, createDefaultIBillingDetail } from "@/data/Models/User/IBillingDetail";
import {User} from "@/data/Models/User/User"
//import CreditCardManagerVue from "@/components/UserSettings/CreditCardManager.vue";

class PasswordData {
  currentPassword: string;
  newPassword: string;
  confirmedNewPassword: string;

  constructor() {
    this.currentPassword = "";
    this.newPassword = "";
    this.confirmedNewPassword = "";
  }
}

export default Vue.extend({
  components: { CreditCardManager, BillingDetails },
  data() {
    return {
      //user: {},
      user: new User(),
      billingDetail: createDefaultIBillingDetail(),
      //billingDetail: new BillingDetail(),

     
      password: new PasswordData(),
      // timeSliceSizeOptions: [
      //   {
      //     text: "15 Minuti",
      //     value: 15
      //   },
      //   {
      //     text: "20 Minuti",
      //     value: 20
      //   },
      //   {
      //     text: "30 Minuti",
      //     value: 30
      //   },
      //   {
      //     text: "60 Minuti",
      //     value: 60
      //   }
      // ],
      hasCards: false
    };
  },

  async mounted() {
    await this.loadInterface();
  },
  methods: {
    async saveUser() {
      //let isUserDataValid = await this.$refs.myValidator.validate();
      let isUserDataValid = await (this.$refs.myValidator as InstanceType<
        typeof ValidationProvider
      >).validate();

      if (isUserDataValid) {
        await account.setUser(this.user);

        //aggiorno lo store, così se ho cambiato qualcosa come lo userUrl, è tutto allineato.
        let storeUser = this.$store.getters.currentUser;
        storeUser.userUrl = this.user.userUrl;
        this.$store.dispatch("updateCurrentUser", storeUser);

        await this.$showAlertBarSaveSuccessfull();
      }
    },
    async saveBillingDetail() {
      let billingDetailsComponent = this.$refs.billingDetailsComponent as InstanceType<typeof BillingDetails>;
      let isBillingDetailValid = await billingDetailsComponent.manualValidation();

      if (isBillingDetailValid) {
        this.billingDetail = billingDetailsComponent.billingDetail;
        await account.setBillingDetail(this.billingDetail);
        await this.$showAlertBarSaveSuccessfull();
      }
    },
    showChangePwdModal() {
      //this.password = {};
      this.password = new PasswordData();
      this.$bvModal.show("changePwdModal");
    },
    async changePassword(e) {
      e.preventDefault();
      let changePwdModalValidator = this.$refs.changePwdModalValidator as InstanceType<typeof ValidationProvider>;
      let valid = await changePwdModalValidator.validate();

      if (valid) {
        await account.changePassword(
          this.password.currentPassword,
          this.password.newPassword
        );
        this.$bvModal.hide("changePwdModal");
        this.$showAlertBar("Password cambiata correttamente", "success");
      }
    },
    async showHelpName() {
      await this.$showHtmlModalMessage(
        "Info",
        `
        Il campo "Nome" viene visualizzato all'interno della tua pagina di prenotazione.

        `
      );
    },
    async showHelpUserUrl() {
      await this.$showHtmlModalMessage(
        "Info",
        `

        Il campo "Nome Url per Prenotazione" corrisponde ad una parte del "link appuntamento" che condividi con i tuoi clienti/colleghi.<br><br>
        Modificando questo campo personalizzi parte del link che condividi.<br><br>

        Per capire meglio considera che il link normalmente è composto in questo modo:<br> <br>
        https://v2.schedeo.it/book/ +<br>
        <strong>[NomeUrlPerPrenotazione]</strong>/ + <br>
        [NomeUrlAppuntamento]<br><br>

        `
      );
    },
    // async showHelpTimeSliceSize() {
    //   await this.$showHtmlModalMessage(
    //     "Info",
    //     `
    //     Con il campo "Dimensione Slot Calendario di Prenotazione" puoi specificare la frequenza in cui saranno disponibili i tuoi slot.<br><br>
    //     Ad esempio:
    //     <br>
    //     <ul>
    //       <li>se scegli 15 gli slot saranno simili a 09.00 / 09.15 / 09.30...</li>
    //       <li>se scegli 30 gli slot saranno simili a 09.00 / 09.30 / 10.00...</li>
    //       <li>se scegli 60 gli slot saranno simili a 09.00 / 10.00 / 11.00...</li>
    //     </ul>`
    //   );
    // },
    async loadInterface() {
      this.user = await account.getUser();
      let result = await account.getBillingDetail();

      //se ho trovato billing details li associo altrimenti inizializzo.
      this.billingDetail = result?result:createDefaultIBillingDetail();

      let creditCardManagerComponent = this.$refs.creditCardManager as InstanceType<typeof CreditCardManager>;
      await creditCardManagerComponent.loadInterface();
    },
    /**
     * @param customerCards {Array}
     */
    onCustomerCardsChanged(customerCards) {
      this.hasCards = customerCards.length > 0;
    }
  }
});
