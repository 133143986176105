

















import {
  getCustomerCards,
  deleteCustomerPaymentMethod
} from "@/data/subscriptionService";
import Vue, { PropType } from "vue";
import "@/data/Models/Card"
export default Vue.extend({
  name: "credit-card-manager",
  data() {
    return {
      customerCards: [] as any[]
    };
  },
  watch: {
    customerCards() {
      this.$emit("customer-cards-changed", this.customerCards);
    }
  },
  async mounted() {
    //await this.loadInterface();
  },
  methods: {
    async loadInterface() {
      this.customerCards = await getCustomerCards();
    },
    /**
     * @param {Card} card
     */
    async deleteCustomerPaymentMethod(card) {
      let confirmed = await this.$showConfirmDelete(
        `Sei sicuro di voler cancellare la carta ${card.brand} ${card.last4}?`
      );
      if (confirmed === true) {
        await deleteCustomerPaymentMethod(card.id);
        await this.loadInterface();
      }
    }
  }
});
