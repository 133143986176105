<template>
  <div>
    <b-card no-body>
      <div>
        <b-card-header v-if="hasHeader">
          <slot name="pageHeader"> </slot>
        </b-card-header>
        <b-card-body>
          <slot name="pageBody"> </slot>
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
    name: "base-page-layout",
    computed: {
        hasHeader() {
            return !!this.$slots.pageHeader
        }
    },
};
</script>
