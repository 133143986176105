import { ReceiverTeamInvitation } from "@/data/Models/Team/ReceiverTeamInvitation";
import apiClient from "@/util/ApiClient";

export const kickMember = async function(teamId: string, userId: number) {
  return apiClient.delete("TeamMembers/KickMember", {
    params: { teamId, userId }
  });
};

//   export const inviteMember = async function(teamId:string, receiverMail:string) {
//     return apiClient.post("TeamMembers/InviteMember",
//         { teamId, receiverMail }
//       );
//   };

export const inviteMember = async function(
  teamId: string,
  receiverMail: string
) {
  return apiClient.post("TeamMembers/InviteMember", null, {
    params: { teamId: teamId, receiverMail: receiverMail }
  });
};

export const getReceiverTeamInvitation = async function(
  teamInvitationId: string
): Promise<ReceiverTeamInvitation> {
  let result = await apiClient.get("TeamMembers/GetReceiverTeamInvitation", {
    params: { teamInvitationId: teamInvitationId }
  });
  return result.data;
};

export const acceptInvite = async function(teamInvitationId: string) {
  return apiClient.post("TeamMembers/AcceptInvite", null, {
    params: { teamInvitationId }
  });
};


export const refuseInvite = async function(teamInvitationId: string) {
  return apiClient.post("TeamMembers/RefuseInvite", null, {
    params: { teamInvitationId }
  });
};

export const cancelInvite = async function(teamInvitationId: string) {
  return apiClient.delete("TeamMembers/CancelInvite", {
    params: { teamInvitationId }
  });
};
