<template>
  <div>
    <a v-if="this.logoUrl === ''" href="https://www.schedeo.it" target="_blank">
      <div class="text-center">
        <div style="font-size: 0.9rem">Powered by</div>
        <img
          src="/img/brand/Logo-WhiteSmallRect.jpg"
          class="navbar-brand-img"
          alt="..."
          style="width: 160px; padding-bottom: 20px;"
        />
      </div>
    </a>
    <div v-else class="text-center pb-3">
      <img class="custom-logo" :src="this.logoUrl" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "logo-component",
  props: {
    logoUrl: { type: String, default: "" }
  }
});
</script>

<style>
.custom-logo {
  max-width: 200px;
  max-height: 200px;
  border-radius: 10px;
}
</style>
