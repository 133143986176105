<!-- 
Ho creato il componenete LogoManager.vue perchè mi serviva per team.
Per velocizzare non ho refatorato questo, ma alla prima occasione che bisogna metterci le mani unificare
E fare tutto con il componente dedicato.
-->
<template>
  <div>
    <b-card no-body class="page-card">
        <b-card-header>
          <label class="helpLabel" @click="showHelpLogo">Istruzioni</label>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="6">
              <div
                v-if="isValidObject(user) && user.fullLogoUrl != ''"
                class="logo-container"
              >
                <!-- <img class="logo-img" :src="user.fullLogoUrl" /> -->
                <b-img class="logo-img" :src="user.fullLogoUrl" thumbnail />
                <div class="p-2">
                  <b-button
                    href="javascript:;"
                    variant="button"
                    class="btn-link"
                    @click="deleteLogo"
                    >Elimina Logo</b-button
                  >
                </div>
              </div>
              <b-form-file
                placeholder="Nessun file selezionato"
                drop-placeholder="Drop file here..."
                v-model="logoFile"
                browse-text="Scegli un nuovo logo"
                @input="uploadLogo"
                ref="fileInput"
              ></b-form-file>
            </b-col>
          </b-row>
        </b-card-body>
      
    </b-card>
  </div>
</template>

<script>
import { uploadLogo, deleteLogo } from "@/data/files";
import { getUser } from "@/data/account";

import "@/data/Models/UserDTO";

export default // mixins(globalMixin).extend (
{
  name: "logo-upload",
  data() {
    return {
      logoFile: null,
      /**
       * @type {UserDTO}
       */
      user: null
    };
  },
  async mounted() {
    await this.loadInterface();
  },

  methods: {
    async loadInterface() {
      this.user = await getUser();
      this.logoFile = null;
    },

    async uploadLogo() {
      if (this.logoFile != null) {
       
        await uploadLogo(this.logoFile);
        await this.$showAlertBarSaveSuccessfull();
        await this.loadInterface();
      }
    },
    async deleteLogo() {
      await deleteLogo();
      await this.loadInterface();
    },
    async showHelpLogo() {
      await this.$showHtmlModalMessage(
        "Info",
        `
        Se carichi un logo questo sarà visibile all'interno della tua pagina di prenotazione in modo che i tuoi clienti ti riconoscano subito.
        `
      );
    }
  }
};
// )
</script>

<style scoped>
.logo-img {
  max-height: 200px;
}
.logo-container {
  text-align: center;
  padding: 20px;
  max-width: 300px;
  
}
::v-deep .custom-file-label:after {
  /*bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    content: "Browse";
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;*/
  background-color: #5e72e4;
  color: white;
}
</style>
