
































































import Vue, { PropType } from "vue";

import { Team } from "@/data/Models/Team/Team";
import { getTeams } from "@/data/services/TeamServices/TeamService";
export default Vue.extend({
  name: "teams-view",
  data() {
    return {
      teams: [] as Team[]
    };
  },
  async mounted() {
    this.teams = await getTeams();
  },
  methods: {
    async showHelp() {
      await this.$showHtmlModalMessage(
        "Info",
        `I team sono gruppi di persone che lavorano insieme su determinate pagine di prenotazione.<br/> <br/>
        Ad esempio, se lavori con un collega e spesso avete appuntamenti insieme, puoi creare un team composto da te e lui. In questo team potrai creare un appuntamento in cui voi due siete gli organizzatori. <br/> <br/>
        
        `
      );
    }
  }
});
